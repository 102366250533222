@import 'mixin/mixin.scss';
@import 'fonts.scss';

.reportPreloader{
	height: 0;
	line-height: 0;
}

.reports_page {
	display: flex;
	align-content: stretch;
	align-items: stretch;
	min-height: calc(100vh - 46px);
	overflow-x: hidden;
}

.report {
	padding-bottom: 25px;
	position: relative;
}

.subNameTable {
	// border-bottom: 1px solid $grey_line;
	font-size: 16px;
	font-weight: bold;
	// margin-left: 24px;
	padding: 15px 0px;
}

.zone_tag_select {
	// padding: 15px 0px;

	.select_zone_container {
		z-index: 1;
	}
}

.additionallParams {
	display: block;
	// padding-top: 7px;

	.input {
		display: inline-block;
		height: 21px;
		margin: 12px 5px 15px 0;
	}
}

.change_size {
	// background: #FFF;
	width: 35px;
	height: 35px;
	background: linear-gradient(to right bottom, #f5f5f5 48%, #f5f5f5 2%, transparent 50%);

	svg {
		transform: rotate(45deg) translate(-90%, 4%);
	}

	.symbol {
		fill: rgb(14, 14, 14);
		opacity: 0.3;

	}
}

.reports_container {
	width: calc(100% - 342px);
	height: calc(100vh - 46px);
	border-radius: 1px;
	background-color: rgb(255, 255, 255);
	box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2);
	transition: width 0.4s ease-out;
	overflow: auto;
	@extend .scroll;

	.name_container {
		padding: 0px 24px 17px 20px;

		&:after {
			@include clear;
		}

	}

	.report_name {
		display: inline-block;
		font-family: $Rbold;
		font-weight: 700;
		font-size: 16px;
		color: rgb(14, 14, 14);
		height: 30px;
		line-height: 30px;
	}

	.export_btn {
		display: inline-block;
		float: right;
		vertical-align: top;

		.secondary_btn {
			margin-left: 10px;
		}
	}
}

.demo_report {
	padding-top: 184px;
	width: 100%;
	text-align: center;
	background-image: url('/images/table-illustration@2x.png');
	background-repeat: no-repeat;
	background-position: center 58px;
	-webkit-background-size: 328px 86px;
	-o-background-size: 328px 86px;
	background-size: 328px 86px;

	.name_block {
		font-size: 16px;
		color: rgb(14, 14, 14);
		margin-bottom: 20px;
	}

	.name_block,
	.grey {
		margin-left: auto;
		margin-right: auto;

		max-width: 390px;
		text-align: center;
	}

	.grey {
		font-size: 1.4rem;
		padding: 0px 15px;
		line-height: 20px;
	}
}

$active_max_height: 367px;

.select_type_report_container {
	position: relative;
	height: 35px;
	width: 302px;
	max-width: 100%;
	margin-bottom: 31px;
	z-index: 4;

	.input_container{
		position: relative;
		.triangle {
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			border-bottom: none;
			border-top: 4px solid black;
			height: 4px;
			position: absolute;
			right: 12px;
			@include PosAbsVertical;
			z-index:2;
		}
	}
	.select_type_report {
		max-height: 0px;
		overflow: hidden;
		@include transitionall;
		position: absolute;
		top: 38px;

		.edit_btn {
			transition: opacity 0.4s ease;
			border-radius: 50%;
			@include PosAbsVertical;
			right: 4px;
			width: 23px;
			height: 23px;
			opacity: 0;
		}

		ul {
			@extend .scroll;
			overflow-y: auto;
			width: 322px;
			list-style: none;
			border-radius: 2px;
			background-color: rgb(255, 255, 255);
			border: solid 1px rgb(201, 203, 206);
			position: relative;
			padding-bottom: 46px;

			li {
				@include transitionall;
				padding: 10px 10px 10px 24px;
				color: rgb(14, 14, 14);
				cursor: pointer;
				font-size: 13px;
				display: flex;
				position: relative;

				&:hover {
					background-color: rgb(65, 121, 248) !important;
					color: #FFF;

					.edit_btn {
						opacity: 1;
					}
				}
			}
		}

		ul::-webkit-scrollbar-track {
			margin-bottom: 48px;
		}


		.add_new {
			position: absolute;
			height: 46px;
			background-color: rgb(245, 245, 245);
			bottom: 0px;
			right: 1px;
			width: 320px;
			border-top: 1px solid rgb(234, 234, 234);
			visibility: hidden;
			text-align: left;
			padding-left: 24px;
			text-decoration: none;
			line-height: 46px;
			color: #0e0e0e;

			&:hover {
				background-color: rgb(65, 121, 248);
				color: #FFF;
			}

			span {
				margin-right: 10px;
				font-size: 14px;
				line-height: 14px;
				display: inline-block;
			}
		}
	}

	.select_list_active {
		height: $active_max_height;
		max-height: $active_max_height;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

		.add_new {
			visibility: visible;
		}

		ul {
			height: $active_max_height - 46px;
		}
	}

	.selected_item {
		width: 302px;
		max-width: 99%;
		height: 35px;
		border-radius: 4px;
		background-color: rgb(255, 255, 255);
		border: solid 1px rgb(215, 217, 220);
		position: relative;
		padding-right: 16px;
		padding-left: 16px;
		text-align: left;
		color: rgb(154, 160, 167);
		margin-bottom: 3px;
		z-index: 2;

		.text {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			display: block;
			width: 100%;
		}
	}
}

.select_vehicle_container,
.select_zone_container {
	position: relative;
	margin-bottom: 15px;
	z-index: 3;
	max-width: 100%;

	.input {
		max-width: 100%;
		width: 302px;
		height: 35px;
	}

	.input_container {
		position: relative;
		margin-bottom: 13px;

		.triangle {
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			border-bottom: none;
			border-top: 4px solid black;
			height: 4px;
			position: absolute;
			right: 12px;
			@include PosAbsVertical;
		}
	}

	.list {
		list-style: none;
		border-radius: 6px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
		position: absolute;
		top: 39px;
		width: 100%;
		max-height: 0px;
		overflow: hidden;
		@include transitionall;

		li {
			min-height: 33px;
			cursor: pointer;
			font-size: 13px;
			padding: 10px 14px;
			@include collapsedText;

			&:hover {
				background-color: rgb(65, 121, 248);
			}
		}

		.list_name {
			background-color: white;
			color: #9aa0a7;
			text-transform: uppercase;
			font-family: $Rmedium;
			font-weight: 500;
			cursor: default;

			&:hover {
				background-color: white;
			}
		}

		.group_list {
			max-height: 99px;
			border-top: 1px solid rgb(215, 217, 220);
			overflow: auto;
			@extend .scroll;
		}

		.item_list {
			max-height: 132px;
			overflow: auto;
			@extend .scroll;
		}
	}

	.select_list_active {
		max-height: 251px;
	}

	.remove {
		opacity: 0.5;
		fill: rgb(14, 14, 14);
	}

	.removeAllItems{
		position: absolute;
		right: 25px;
		top: 6px;
		border-radius: 50%;
		height: 20px;
		background-color: $blue;
		opacity: 0.7;
		svg {
			border: 2px solid $blue;
			border-radius: 50%;
				circle{
					fill: $blue;
				}
				path{
					fill: white;
				}
			}
	}
}

.selected_tag {
	font-size: 13px;
	color: rgb(255, 255, 255);
	min-height: 24px;
	// padding-top: 5px;
	// padding-bottom: 5px;
	border-radius: 100px;
	background-color: $blue;
	padding-left: 10px;
	display: inline-flex;
	align-items: center;
	cursor: default;
	margin-bottom: 10px;
	margin-right: 10px;
	max-width: 100%;

	&:hover {
		background-color: rgb(115, 158, 255);

		.remove {
			// background-color: $blue;

		}
	}

	.remove {
		background: transparent;
		margin-left: 3px;
		transition: background 0.4s ease;
		-webkit-border-radius: 50%;
		border-radius: 50%;
		border: 1px solid $blue;

		svg {
			vertical-align: bottom;
		}

		circle {
			fill: transparent;
		}

		.symbol {
			fill: black;
			opacity: 0.5;
		}

		&:hover {
			opacity: 1;
			background: rgb(65, 121, 248);
		}
	}
	.removeAllItems{

	}
	.value {
		@include collapsedText;
		text-transform: uppercase;

	}
}

.kill_padding {
	.selected_item {
		padding: 0px;
	}
}

.settings_sidebar {
	padding: 28px 20px;
	background-color: rgb(245, 245, 245);
	width: 342px;
	transition: width 0.4s ease, opacity 0.6s ease-in;
	opacity: 1;
	visibility: visible;
	min-height: calc(100vh - 46px);
	max-height: calc(100vh - 46px);
	-ms-overflow-y: auto;
	overflow-y: auto;
	@extend .scroll;

	.name_block {
		opacity: inherit;
		font-size: 16px;
		color: rgb(14, 14, 14);
		margin-bottom: 12px;
	}
	.from_in_day_to_in_day_params{
		.choose_time_container{
			margin-left: 0;
		}
		div{
			display: inline-block;
			margin-right: 10px;
		}
	}

	.choose_time_container {
		margin-left: 10px;
		position: static;
	}

	.vehicle_sensors {
		padding: 10px 0px;

		h3 {
			padding-bottom: 10px;
		}

		p {
			padding-left: 15px;
			padding-bottom: 5px;
		}
	}
	.calendar {
		margin-bottom: 13px;
	}

	.calendar-date-panel {
		left: auto;
		bottom: auto;
	}

	.calendar-date-panel {
		display: none;
		z-index: 2;
	}

	.info_about {
		margin-bottom: 10px;
		color: rgb(255, 77, 77);
		min-height: 13px;
	}

	.calendar-input {
		width: 149px;

		@include placeholder {
			color: black;
		}
	}

	.calendar_triger {
		left: 125px;
	}

	.primari_btn {
		width: 145px;
	}
}

.report_created {
	text-align: center;

	.name_pop_up {
		font-size: 16px;
		color: rgb(14, 14, 14);
		text-align: center;
		margin-bottom: 10px;
	}

	.text_under_name {
		font-size: 14px;
		color: rgb(14, 14, 14);

		span {
			font-family: $Rmedium;
			font-weight: 500;
			font-weight: 500;
		}

		margin-bottom: 30px;
	}

	.grey_container {
		background: rgb(200, 195, 199);
		width: 380px;
		max-width: 100%;
		height: 346px;
		min-height: 100%;
	}
}

.after_padding {
	padding: 5px 24px;

	.desc_ev {
		font-family: $Rmedium;
		font-weight: 500;
	}

	.value {
		font-family: $Roboto;
		font-weight: 400;
	}

	.units {
		color: $grey_text;
	}
}

.content_after_table {
	padding-top: 15px;
}

.report {

	.report_tables {
		overflow: auto;
		width: 100%;
		min-height: 160px;
		// max-height: calc(100vh - 128px);
		// @extend .scroll;
	}

	.one_table {
		min-width: min-content;
		min-width: -moz-min-content;
		min-width: -webkit-min-content;
		width: 100%;
		// margin: auto;
		margin-bottom: 15px;

	}

	.table_name {
		height: 40px;
		line-height: 40px;
		background-color: rgb(245, 245, 245);
		padding-left: 20px;

		.license_plate {
			font-family: $Rbold;
			font-weight: 700;
			font-size: 16px;
			letter-spacing: 0.2px;
			color: rgb(14, 14, 14);

		}

		.vehicle_name {
			letter-spacing: 0.2px;
			color: rgb(154, 160, 167);
			font-size: 16px;
		}
	}

	.table {
		padding-left: 24px;
		width: max-content;
		background: #FFF;
		min-width: 100%;
		margin-top: 0;
	}

	.table_head {
		height: 60px;
		background: #FFF;

		th {
			text-align: right;
			text-transform: uppercase;
			font-size: 11px;
			font-weight: 500;
			color: rgb(130, 141, 151);
			font-family: $Rmedium;
			font-weight: 500;
			min-width: 62px;
			padding-right: 19px;
			max-width: 168px;
			padding-left: 26px;
			border-bottom: 2px solid rgb(235, 242, 248);
			;

			.units {
				font-size: 9px;
				display: inline-block;
				padding-left: 5px;
				color: rgb(14, 14, 14);
			}
		}
	}

	.one_line {
		height: 40px;

		td {
			font-size: 14px;
			line-height: 40px;
			text-align: right;
			color: rgb(14, 14, 14);
			min-width: 62px;
			padding-right: 19px;
			max-width: 155px;
			padding-left: 26px;
			border-bottom: 1px solid rgb(235, 242, 248);


		}
	}

	.last_line {
		font-family: $Rmedium;
		font-weight: 500;
	}
}

.block_container {
	// display: flex;
}

.no_data_table {
	text-align: center;
	padding: 30px;
}

.no_data_period {
	font-size: 16px;
	color: rgb(14, 14, 14);
}

.grey {
	font-size: 14px;
	line-height: 27px;
	color: rgb(154, 160, 167);
}

.logical_input {
	.one_line td {
		max-width: fit-content;
		text-align: left;
	}

	.table_head th {
		padding-left: 26px;
		text-align: left;
	}

	.keyName {
		font-family: $Rmedium;
		font-weight: 500;
		display: inline-block;
		padding-right: 10px;
	}

	ul {
		padding-left: 24px;
		padding-top: 15px;

		li {
			list-style: none;
			margin-bottom: 10px;

			.date_ev {
				font-family: $Rmedium;
				font-weight: 500;
				display: inline-block;
				padding-right: 10px;
				color: $grey_text;
			}

			.desc_ev {
				font-family: $Rmedium
			}
		}
	}
}

.report_fuel {
	.one_line td {
		max-width: fit-content;
		text-align: left;
	}

	.table_head th {
		padding-left: 26px;
		text-align: left;
	}

	.keyName {
		font-family: $Rmedium;
		font-weight: 500;
		display: inline-block;
		padding-right: 10px;
	}

	.date_name {
		font-family: $Rmedium;
		font-weight: 500;
		display: inline-block;
		padding-right: 10px;
		color: $grey_text;
	}

	.units {
		font-family: $Rmedium;
		font-weight: 500;
		display: inline-block;
		padding-right: 5px;
		padding-left: 5px;
		color: $grey_text;
	}

	ul {
		padding-left: 24px;
		padding-top: 15px;

		li {
			list-style: none;
			margin-bottom: 10px;

		}
	}
}

.inDay {
	.name_block {

		span {
			padding-left: 10px;
			padding-right: 67px;
		}
	}

	.choose_time_container {
		margin-left: 0px;
		margin-right: 10px;
	}
}

.head_getLink {
	display: flex;
	align-items: center;
	padding-left: 15px;
	padding-top: 20px;

	span {
		margin-right: 10px;
	}

	.secondary_btn {
		margin-right: 10px;
	}
}

.copy_block {
	padding-top: 15px;
	display: flex;
	align-items: center;
}

.getLink {

	.input {
		width: 500px;
		margin-left: 15px;
		height: 26px;
		margin-right: 15px;
		padding-left: 0px;
	}

	.listOfKeys {
		padding: 15px;
	}

	.apiKey {
		padding-right: 15px;
		font-family: $Rbold;
		font-weight: 700;
		color: $grey_text;
	}

	.apiKeycomment {
		padding-right: 10px;
		color: $blue;
	}

	.oneKey {
		border-bottom: 1px solid $grey_line;
		padding-bottom: 15px;
	}

	.inlineFlex {
		display: inline-flex;
		align-items: center;
		padding-right: 15px;

		.primari_btn {
			width: auto;
		}
	}

	.copy_btn {
		margin-right: 15px;
	}

	.delete_btn {
		width: 20px;
		height: 20px;
		margin-right: 15px;
	}

	.scrollContainer {
		@extend .scroll;
		overflow: auto;
	}

	.btn_Add {
		position: absolute;
		right: 19px;
		top: 52px;
		left: auto;
	}

	.form_newKey {
		padding: 15px;

		.primari_btn {
			width: auto;
			margin-right: 15px;
		}

		.textarea {
			width: 400px;
			height: 50px;
		}
	}
}

@media screen and (max-width: 1000px) {
	.report .export_btn .secondary_btn {
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 767px) {
	.reports_container {
		position: relative;

	}

	.select_vehicle_container,
	.select_zone_container {
		width: 302px;
	}

	.change_size {
		position: absolute;
		left: 0px;
		top: 0px;
	}

	.report {
		.name_container {
			padding: 0px 24px 17px 44px;
		}
	}

	.settings_sidebar {
		padding: 15px 10px;
	}

	.look_report {
		.change_size {
			left: -36px;
			top: 1px;
			background: linear-gradient(to left bottom, #fff 48%, #f5f5f5 2%, transparent 50%);

			svg {
				transform: rotate(135deg) translate(-90%, 4%);
			}

		}
	}
}

.block_of_report {
	padding: 15px 0px 0px 6px;

	h3 {
		font-weight: bold;
		// margin-bottom: 15px;
		font-size: 16px;
		// min-height: 70px;
	}

}

.email_form_container {
	padding: 25px 15px 0px;
	text-align: left;

	.input {
		width: 300px;
		height: 32px;
		margin-bottom: 35px;
	}
}

.link_for_reportOnGoogleDisk {
	padding: 25px 15px 0px;
	text-align: left;
	margin-bottom: 35px;

	.input {
		width: 300px;
		height: 32px;
	}

	.copy_btn {
		margin-left: 15px;
	}
}

@media screen and (max-width: 426px) {
	.settings_sidebar {
		width: 100%;
		min-width: 100%;
		padding-right: 30px;
	}

	.kill_padding {
		min-width: auto;
	}

	.report {
		.export_btn {
			position: absolute;
			left: -179px;
			top: 361px;
			max-width: 180px;
			margin-bottom: 5px;
		}
	}
}

@media screen and (max-width: 375px) {
	.settings_sidebar {
		padding-right: 10px;
	}

	.select_type_report {
		ul {
			li {
				padding-left: 8px;
			}

		}
	}

	.settings_sidebar {
		width: 100%;
	}
}

@media screen and (max-width: 375px) {
	.select_type_report {
		ul {
			width: 100%;
		}
	}
}


