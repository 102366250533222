@import "mixin/mixin.scss";
@import "fonts.scss";
.oneNotif_container{
  display: flex;
}
.notif_btn{
	background: none;
	border: none;	
	position: relative;
	text-align: center;
	width: 25px;
	height: 25px;
	&:hover{
		fill: white!important;
	}
	svg{
		@include PosAbsCent;
	}
}
.notif_counter{
	position: absolute;
	z-index: 2;
	top: -6px;
	display: inline-block;
	min-width: 16px;
	height: 16px;
	line-height: 15px;
	-webkit-border-radius: 16px;
	        border-radius: 16px;
	background-color: rgb(65, 121, 248);
  	border: solid 1px rgb(38, 49, 59);
  	color: #FFF;
  	font-size: 11px;
  	text-align: center;
  	padding-left: 2px;
  	padding-right: 2px;
}
.notif_container{
	position: relative;
	z-index: 1;
}
.notif_hide_scroll{
	position: absolute;
	left: -103px;
	top: 46px;
	width: 403px;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	border-radius: 2px;
	padding-bottom: 36px;
	@include transitionall;
	&:before{
		content: '';
	    position: absolute;
	    margin: auto;
	    top: 5px;
	    left: 133px;
	    z-index: 1;
	    z-index: 1;
	    width: 0;
	    height: 0;
	    border-left: 13px solid transparent;
	    border-right: 13px solid transparent;
	    border-bottom: 12px solid rgb(29, 35, 41);
	}
	.show_all_notif{
		width: 100%;
		height: 36px;
		border-radius: 2px;
		background-color: rgb(14, 14, 14);
		font-size: 13px;
		color: rgb(154, 160, 167);
		position: absolute;
		bottom: 0px;
		z-index: 4;
		display: block;
		text-align: center;
		padding-top: 9px;
		&:hover{
			background-color: rgb(7, 7, 7);
			opacity: 1!important;
		}
	}
}
.list_notification{
	position: relative;
	z-index: 1;
	width: 417px;
	padding-right: 14px;
	max-height: 556px;
	overflow-y: scroll;
	padding-top: 12px;
	border-radius: inherit;
	background-color: rgb(29, 35, 41);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
	&::-webkit-scrollbar { 
	    display: none; 
	    width: 0px;
    	background: transparent; 
	}
	li{
		padding-top: 17px;
		border-bottom: rgb(14, 14, 14) 1px solid;
		width: 100%;
		&:hover{
			background-color: rgb(38, 49, 59);
			cursor: pointer;
		}
	}
}
.head_notif{
	@include clear;
	border-bottom: rgb(14, 14, 14) 1px solid;
	padding-top: 0px;
	line-height: 46px;
	height: 46px;
	background-color: rgb(29, 35, 41);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
	position: relative;
	top: 12px;
	padding-left: 30px;
    padding-right: 30px;
	border-bottom: rgb(14, 14, 14) 1px solid;
	position: relative;
	z-index: 2;
	background-color: rgb(29, 35, 41);
	span{
		font-size: 1.6rem;
	}
}
.one_notif{
		height: auto;
		min-height: 97px;
		padding-top: 18px;
		position: relative;
		padding-bottom: 18px;
		@include transitionall;
		.close{
			fill: rgb(154, 160, 167);
			position: absolute;
			right: 15px;
			top: 15px;
			visibility: hidden;
			background: transparent;
		}
		&:hover{
			.close{
				visibility: visible;
			}
		}
}
.settings_link{
	float: right;
	font-size: 13px;
	opacity: 0.6;
	svg{
		vertical-align: sub;
		margin-right: 8px;
	}
}

.notif_name{
	font-size: 13px;
  	color: rgb(154, 160, 167);
}
.event_info{
	font-size: 14px;
  	color: rgb(215, 217, 220);
  	padding-bottom: 10px;
  	padding-top: 4px;
}
.event_date{
	opacity: 0.5;
  	font-size: 12px;
  	color: rgb(154, 160, 167);
}
.icon_container, .info_container{
	display: inline-block;
	vertical-align: top;
}
.info_container{
	padding-left: 18px;
}
.notif_license_plate{
	  font-size: 14px;
	  font-weight: bold;
	  color: rgb(255, 255, 255);
}

@media screen and (max-width: 919px){
	.notif_hide_scroll{
		position: fixed;
	    right: 0px;
	    top: 33px;
	    width: 403px;
	    max-width: 100%;
	    left: auto;

	}
	.list_notification{
		max-width: 100%;
	}
	.one_notif{
		.close{
			visibility: visible;
		}
	}
	.head_notif{
		span{
			color: #FFF;
		}
	}
}