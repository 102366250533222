@import "mixin/mixin.scss";

.drop_block{
	display: inline-block;
	border: 1px solid $grey_line;
	width: 220px;
	height: 200px;
	// padding-top: 5px;
	padding-bottom: 5px;

	@extend .scroll;
	overflow-y: auto;
	.item{
		border-bottom: 1px solid transparent;
		cursor: pointer;
		padding-left: 5px;
		padding-right: 5px;
		&:hover{
			border-color: $orange;
		}
	}
	.name_block{
		padding: 7px 7px;
		background: #9AA0A7;
		font-weight: 500;
		color: white;
	}
}
.arrowContainer{
	display: inline-block;
	button{
		&:hover{
			opacity: 0.7;
		}
	}
}
.droppZone{
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}