@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=cyrillic,cyrillic-ext');
@import "mixin/mixin.scss";
@import "sprite.scss";
@import "fonts.scss";

* {
	font-family: $Roboto;
}
.template_group_name{
	display: inline-block;
	min-width: 100px;
	font-weight: bold;	
}

.time_input_container .psevdo_time_input {
	height: 30px !important;
}

#app_mountpoint {
	min-height: 100vh;
}

.preloader {
	width: 100px;
	height: 100px;
	z-index: 15;
	@include PosAbsCent;
}

@mixin not_allowed {
	&:disabled {
		opacity: 0.7;
		opacity: 0.3;
		cursor: not-allowed;
		pointer-events: none;
		// &:not:hover {}
	}
}

@mixin btn_text_style {
	font-size: 13px;
	font-family: $Roboto;
	letter-spacing: 0.2px;
	text-decoration: none;
	// line-height: 13px;
	@include not_allowed;
}

.no_style_btn {
	background: none;
	border: none;
	color: #0e0e0e;

	@include btn_text_style;
}

.test {
	border: red 3px solid;
}

.transitionall {
	transition: all 0.4s ease;
}

* {
	box-sizing: border-box;
	margin: 0px;
	padding: 0px;
	outline: none;
	border: none;
}

input:focus,
	{
	box-shadow: inset 0px 0px 14px -5px rgba(128, 128, 128, 0.60);
}

textarea:focus {
	box-shadow: inset 0px 0px 14px -5px rgba(128, 128, 128, 0.60);
}

html {
	font-size: 10px;
	font-family: $Roboto;
	overflow-x: hidden;
}

body {
	font-size: 1.4rem;
	background: $light_grey_back;
	transition: height 0.4s ease;
}

.page {
	padding-bottom: 40px;
}

a {
	font-size: 1.4rem;
}

.logo {
	height: 26px;
	float: left;
	margin-top: 10px;
	margin-left: 25px;
}

.container {
	margin: auto;
	max-width: 1920px;
	&:after {
		@include clear;
	}
}
$menu_height: 46px;

.content {
	padding: 0px 15px 30px;
	background: #d3d3d31a;
	min-height: calc(100vh - #{$menu_height});
	.content {
		padding: 0px;
		min-height: auto;

	}
}

header {
	background: #1d2329;
	min-height: 46px;
	z-index: 100; //set big index becouse I want to block popup
	position: fixed;
	top: 0;
	width: 100%;
}

main_menu  {
	@extend header;
	top: auto;
	bottom: 0;
	height: 100%;
	left: 0;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	min-height: 14px;
	height: 36px;
	opacity: 0;
	@include transitionall;
	i{
		color: yellow;
	}
	&:hover{
		opacity: 0.9;
	}
	.link_client{
		color: #FFF;

	}
}

.visible_on_mobile {
	display: none;
}

.triangle {
	position: absolute;
	top: 13px;
	right: 7px;
}

.instead_header {
	height: $menu_height
}

.main_menu {
	color: white;
	height: $menu_height;
	padding-left: 30px;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	z-index: 100;

	li {
		display: inline-block;
		// margin-right: 60px;

		a {
			letter-spacing: 0.4px;
			color: inherit;
			text-decoration: none;
			font-family: $Roboto;
			transition: all 0.4s ease;
			cursor: pointer;
			&:hover {
				opacity: 0.7!important;
			}
		}

		
	}
	.right_part{
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		        width: 220px;
	}
	.left_part{
		min-width: 720px;
		width: 71%;
		max-width: 900px;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
	}
	.hidden_content {
		position: absolute;
		top: 16px;
		visibility: hidden;
		height: 0;
		overflow: hidden;
		height: auto;
		width: 150px;
		padding: 18px 20px 20px;
		left: -20px;
		background: #1D2329;
		opacity: 0;

		p {
			margin-bottom: 12px;
			cursor: pointer;

			&:nth-last-child(1) {
				margin-bottom: 0;
			}
		}

	}

	.subMenu_item {
		height: inherit;
		position: relative;
		cursor: default;

		&:hover {
			.hidden_content {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.right_part {
		display: flex;
	}
	.icon_go_to_user_link{
		background: $blue;
		width: auto;
		height: 15px;
		padding: 3px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		font-size: 12px;
	}

	.login_link {
		position: relative;

		.avo4ka {
			@include PosAbsCent;
			left: -27px;
			right: auto;
		}
	}

	.exit_link {
		position: relative;
		margin-right: 15px;

		.exit {
			@include PosAbsCent;
			left: -27px;
			right: auto;

			.st0 {
				fill: white;
			}
		}
	}

	.global_search {
		width: 214px;
		height: 32px;
		border-radius: 100px;
		background-color: #0e0e0e;
		padding: 15px;
		color: #FFF;

		@include placeholder {
			opacity: 0.7;
		}
	}
}

.left_part {
	margin: auto;
	margin-left: 60px;
	display: flex;
}

#global_search {
	margin-right: 213px;
}

@import "clients.scss";

.displaynone {
	display: none;
}
$height_search_input: 30px;
.search_wrapper {
	position: relative;
	width: 400px;
	max-width: 100%;
	display: inline-flex;
	height: $height_search_input;

}

.search_vs_button{
	display: inline-flex;
	max-width: 100%;
	margin-bottom: 15px;
	.search_wrapper{
		margin-right: 10px;
		max-width: calc(100% - 10px - 75px);
	}
	.btn_call_search_request{
		height: $height_search_input;
		padding: 6px 17px;
		// max-width: 20%;
		min-width: 75px;
	}
}

.search_input {
	width: 400px;
	max-width: 100%;
	height: $height_search_input;
	background-color: #ffffff;
	border: $border;
	padding-left: 34px;
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlBhcmtpbmciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEyIDEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTExLjcsMTAuM0w5LjIsNy43QzkuNyw2LjksMTAsNiwxMCw1YzAtMi44LTIuMi01LTUtNVMwLDIuMiwwLDVzMi4yLDUsNSw1YzEsMCwyLTAuMywyLjgtMC44bDIuNSwyLjVjMC40LDAuNCwxLDAuNCwxLjQsMEMxMi4xLDExLjMsMTIuMSwxMC43LDExLjcsMTAuM3ogTTUsOEMzLjMsOCwyLDYuNywyLDVzMS4zLTMsMy0zczMsMS4zLDMsM1M2LjYsOCw1LDh6IiBmaWxsPSIjOWFhMGE3Ii8+PC9zdmc+");
	background-position: 11px 7px;
	background-repeat: no-repeat;
	background-size: 15px 15px;
	@include inputBorderRadius;
	&:hover, &:focus{
		background-blend-mode: difference;
	}
}

.input {
	width: 260px;
	max-width: 100%;
	height: 30px;
	background-color: #ffffff;
	border: $border;
	padding-left: 15px;
	vertical-align: top;
	@include inputBorderRadius;

	@include placeholder {
		color: $placeholder;
	}
}

.form_text_area {
	width: 260px;
	max-width: 100%;
	height: 126px;
	border-radius: 6px;
	background-color: #ffffff;
	border: $border;
	padding: 15px;

	@include placeholder {
		color: $placeholder;
	}
}

.btn_black {
	padding: 8px 20px;
	border-radius: $border-radius;
	// background-color: white;
	background-color: $blue;
	font-size: 14px;
	color: white;
	border: 1px solid white;
	cursor: pointer;
	text-decoration: none;
	@extend .transitionall;
	@include not_allowed;

	&:hover {
		opacity: 0.87;
		color: $blue;
		background-color: white;
		border-color: $blue;
	}

	&:active {
		transform: scale(1.01);
	}
}

.btn_plus {
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-color: $blue;
	color: white;
	width: 30px;
	height: 30px;
	text-align: center;
	font-size: 15px;
	border: 1px solid white;
	cursor: pointer;

	&:hover {
		color: $blue;
		border-color: $blue;
		background: #FFF;
	}
}

.small_btn_black {
	@extend .btn_black;
	padding: 3px 8px;
}

.copy_btn {
	position: relative;
	border: 1px solid rgb(215, 217, 220);
	border-radius: 50%;
	width: 28px;
	height: 28px;
	background-color: #FFF;
	cursor: pointer;
	@include transitionall;
	@include PosAbsCent;

	i {
		position: absolute;
		width: 9px;
		height: 9px;
		border-radius: 1px;
		background-color: rgb(255, 255, 255);
		border: solid 2px rgb(14, 14, 14);
		display: block;
		@include transitionall;
	}

	.top_square {
		top: 7px;
		left: 7px;
	}

	.bottom_square {
		top: 11px;
		left: 11px;
		outline: 1px solid white;
	}
}

.add_info {
	display: inline-block;
	float: right;
	text-align: right;
	line-height: 29px;

	.accountType_tubs {
		width: 100%;
		display: flex;
		justify-content: stretch;
		height: 30px;
		margin-bottom: 20px;

		.tubs {
			width: 50%;
			border: 1px solid $grey_line;
			color: #525050;
			background: white;
			cursor: pointer;

			&:hover {
				background: $grey_line;
			}
		}
	}

	&:after {
		@include clear;
	}

	.react-phone-number-input {
		width: 260px;
		margin-bottom: 15px;

		.react-phone-number-input__input {
			height: 30px;
			border-radius: 81px;
			background-color: #ffffff;
			border: solid 1px #4179f8;
			padding-left: 15px;
			vertical-align: top;
		}
	}
}

.add_info_form {
	line-height: 32px;
	visibility: hidden;
	background-color: white;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px #c9cbce;
	text-align: left;
	position: absolute;
	z-index: 2;
	top: 157px;
	left: 0px;
	right: 0px;
	margin: auto;
	width: 580px;
	padding: 6px 20px 20px;
	height: auto;
	opacity: 0;
	transition: opacity 0.4s ease;
	@include formBorderRadius;
	@extend .clear;

	.select_list {
		z-index: 2;
		margin-bottom: 15px;
	}
	.z_index_4{
		z-index: 4;
	}
	.input {
		margin-bottom: 15px;
	}

	.form_text_area {
		// float: right;
		resize: none;
		height: 79px;
	}

	.belongsTo {
		float: right;
	}

	.belongsTo_dealer_block {
		float: right;
		position: relative;

		.dealer_groups_list {
			position: absolute;
			top: 31px;
			left: 0;
			border: 1px solid $border_block;
			width: 100%;
			max-height: 70px;
			@extend .scroll;

			p {
				padding-left: 15px;
				line-height: 24px;

				&:hover {
					background: $grey_firm_style;
					cursor: pointer;
				}
			}
		}
	}

	.btn_black {
		display: block;
		margin: auto;
		padding: 9px 59px
	}

	input[name="password_confirm"] {
		float: right;
	}
}

.close {
	width: 35px;
	height: 35px;
	position: absolute;
	right: -32px;
	top: -28px;
	cursor: pointer;
	visibility: inherit;
	@extend .transitionall;

	&:hover {
		transform: rotate(360deg) scale(1.2);

	}
}

.red_color{
	color: rgba(240, 36, 36, 1);
}

.formError {
	box-shadow: inset 0px 0px 17px -5px rgba(240, 36, 36, 0.88);
}

.form_message {
	min-height: 1.4rem;
	text-align: center;
	margin-bottom: 11px;
}

.table {
	border: 1px solid $color_border_table;
	margin-top: 20px;
	.filter_icon_in_row {
	        width: 27px;
	        height: 27px;
	        padding: 3px;
	        float: right;
	        background: #FFF;
	        border: 1px solid #dfe6ec;
	        border-radius: 2px;
	        cursor: pointer;
	}
	.oneLine {
		vertical-align: top;
		border-bottom: 1px solid $color_border_table;
		height: 2.5rem;
		line-height: 2.5rem;
		transition: all 0.4s ease;

		&:after {
			@extend .clear;
		}

		&:hover {
			box-shadow: inset 0px 0px 17px -5px rgba(142, 143, 144, 0.88);
		}

		.cell_login {
			&:hover {
				opacity: 1;
			}
		}
	}

	.cell {
		float: left;
		border-right: 1px solid $color_border_table;
		height: inherit;
		line-height: inherit;
		padding-left: 15px;
		padding-right: 15px;
		text-overflow: ellipsis;
		overflow: hidden;
		z-index: 1;
		opacity: 0.99;
		@include  clear;
	}

	&:last-child {
		border-bottom: none;

		.cell_edit,
		.cell_delete,
		.cell_copy {
			border-bottom: 1px solid $color_border_table !important;

			&:after {
				display: none !important;
			}
		}
	}

	&:first-child {
		border-top: none;
	}
}

.edit {
	width: 22px;
	height: 22px;
	@include PosAbsCent;
	cursor: pointer;
	@extend .transitionall;

	&:hover {
		fill: grey;
	}
}
.calibrationInfo_i {
	@extend .edit;
	-webkit-border-radius: 50%;
	        border-radius: 50%;

	border: 1px solid grey;
	i{
		font-size: 16px;
		font-style: italic;
		font-weight: bold;
		height: 16px;
	    line-height: 17px;
	    width: 10px;
	    text-align: center;
		@include PosAbsCent;
	}
	&:hover {
		color: grey;
	}
}
.delete {
	width: 22px;
	height: 22px;
	@include PosAbsCent;
	cursor: pointer;

	&:hover {
		fill: #ab4848;
	}
}

.default_table_style {
	@extend .table;
	
	.head_line {
		height: 51px;
		line-height: 1.8rem;
		font-weight: 600;
		background-color: #F9F9F9;
		color: black;

		.cell {
			padding-top: 0.5rem;
			overflow: hidden;
		}

		.cell_delete,
		.cell_edit,
		.cell_copy {
			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 0px;
				left: 0px;
				height: 2px;
				width: 100%;
				background: #FFF;
			}
		}
	}

	.oneLine:last-child {

		.cell_delete,
		.cell_edit,
		.cell_copy {
			background: #FFF;

			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 1px;
				left: 0px;
				height: 2px;
				width: 100%;
				background: #FFF;
			}
		}
	}

	.oneLine {
		display: flex;
		-webkit-align-items: stretch;
		align-items: stretch;
		min-height: 35px;
		color: #525050;
		.cell_server_name{
			a{
				color: #525050;
				text-decoration: none;
			}
		}
		.cell_login {
			color: black;

			a {
				color: inherit;
			}
		}

		&:hover {

			.cell_delete,
			.cell_edit,
			.cell_copy {

				svg {
					opacity: 1;
				}

				.copy_btn, .calibrationInfo_i {
					opacity: 1;
				}
			}
		}

		.cell {
			padding-top: 0.5rem;
			min-height: inherit;
			overflow: hidden;

			p {
				padding-top: 0.5rem;
				padding-right: 10px;
				display: inline-block;
			}
		}
		.cell_blocked{
			display: inline-flex;
		}
		.cell_info {
			padding-top: 0px;
			text-align: center;
			line-height: 0;
			display: inline-flex;
			-webkit-align-content: center;
			        align-content: center;
			
		}

		.cell_login {
			position: relative;
			z-index: 1;
			overflow: visible;

			&:hover {
				opacity: 1;
				z-index: 2;

				.admin_description {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.cell_id {
			width: 140px;
		}

		.cell_delete,
		.cell_edit,
		.cell_copy {
			width: 40px;
			min-width: 30px;
			padding-left: 5px;
			padding-right: 5px;
			border: none;
			position: relative;
			background: #FFF;

			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 0px;
				left: 0px;
				height: 2px;
				width: 100%;
				background: #FFF;
			}

			svg {
				opacity: 0;
				transition: all 0.3s ease;
			}

			.copy_btn {
				opacity: 0;
				transition: all 0.3s ease;
			}
		}
		.calibrationInfo_i{
			opacity: 0;

		}
		.cell_add {
			@extend .cell_edit;
			width: 35px;
			min-width: 35px;
			padding-top: 2px;
		}

		.cell_copy {
			min-width: 35px;
		}

		.cell_byWhom {
			position: relative;

			&:hover {
				.edit_belongsTo {
					opacity: 1;
				}

				.target_group {
					border: 2px solid $color_border_table;
				}
			}
		}

	}
}

.administratorList,
.clientlist,
.devicelist, .device_list,
.transportlist,
.userlist,
.server_list,
.logTable {
	@extend .default_table_style;
}

.logTable {
	.oneLine {
		height: auto;
	}
}

.administratorList {
	overflow: auto;
	.oneLine {
		min-width: 1200px;

		.cell_byWhom,
		.cell_login,
		.cell_desc {
			width: 9%;
		}
	}


	.premissions {
		display: flex;
		align-items: stretch;
		width: calc(76% - 80px);
		justify-content: space-between;

		.cell {
			width: 100%;
		}
	}
}

.clientlist,
.userlist {
	.oneLine:first-child {
		height: 40px;
		line-height: 2.8rem;
	}

	.cell {
		width: 20%;
	}

	.cell_desc {
		position: relative;
		padding-right: 30px;

		.edit {
			position: absolute;
			margin: auto;
			top: 0px;
			bottom: 0px;
			right: 10px;
			z-index: 1;
			opacity: 0;
			left: auto;
			@extend .transitionall;
		}

		&:hover {
			.edit {
				opacity: 1;
			}
		}
	}
}

.userlist {
	.cell {
		width: 25%;
	}

	.cell_delete,
	.cell_edit {
		float: right;
		margin-right: 0px;
		margin-left: auto;

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0px;
			left: 0px;
			height: 2px;
			width: 100%;
			background: #FFF;
		}
	}
}

.server_list {
	.cell {
		width: 33%;
	}

	.oneLine:first-child {
		height: 40px;
		line-height: 2.8rem;
	}

	.list_maped_server {
		position: relative;
		overflow: visible !important;
		padding-top: 0px !important;

		div {
			overflow: hidden;
			height: inherit;
			width: 100%;
			padding-left: 15px;
			padding-right: 15px;
		}
		.filter_icon_in_row{
			position: absolute;
			top:3px;
			right: 15px;
		}
		p {
			display: inline-block;
		}

		&:hover {
			z-index: 3;

			div {
				position: absolute;

				overflow: visible;
				height: auto;
				top: 0;
				left: 0;
				border: 1px solid $border_block;
				background: white
			}

			p {
				display: block;
			}

		}
	}
}

.devicelist,  .device_list{
	.oneLine:first-child {
		height: 40px;
		line-height: 2.8rem;
	}

	.cell {
		width: 33%;
	}
	.cell_owner{
		display: flex;
		justify-content: space-between;
		position: relative;
		.goToClient{
			width: 15px;
			height: 15px;
			position: absolute;
			right: 2px;
		}
	}
	.cell_name {
		a {
			color: #525050;
			text-decoration: none;

			&:hover {
				border-bottom: 1px solid black;
				opacity: 0.5;
			}

		}
	}

	.cell_title {
		position: relative;

		.this_name {
			max-width: 100%;
			-ms-text-overflow: ellipsis;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.visible_on_hover {
			visibility: hidden;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 15px;
			min-width: 100%;
			width: max-content;
			background: #FFF;
			border-right: $color_border_table;

		}

		&:hover {

			.visible_on_hover {
				visibility: visible;
			}
		}
	}

	.device_in_trash {

		// opacity: 0.2;
		.cell {
			background: rgba($grey_line, 0.4);
		}

		* {
			color: rgba(0, 0, 0, 0.3);
		}

		.cell_status {
			color: rgba(0, 0, 0, 1);
		}

		.cell_delete {
			svg {
				opacity: 1 !important;
			}
		}
	}

	.device_is_discontected {
		* {
			color: #7a91c6;
		}

		.cell_status {
			color: #7a91c6;
		}
	}
}

// .cell_status {
// 			font-weight: 700;
// 		}
.transportlist {
	.oneLine:first-child {
		height: 40px;
		line-height: 2.8rem;
	}

	.cell {
		width: 25%;
	}

	.cell_name {
		min-width: 240px;
	}
}

.admin_description {
	position: absolute;
	z-index: 5;
	line-height: 1.6rem;
	width: 80%;
	left: 100%;
	top: 0px;
	visibility: hidden;
	opacity: 0;
	background-color: white;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px #c9cbce;
	color: black;
	text-align: left;
	min-height: 35px;
	padding: 10px;
	padding-bottom: 24px;
	min-width: 178px;
	@extend .transitionall;

	&:before {
		content: '';
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		border: 8px solid transparent;
		border-right: 8px solid white;
		top: 9px;
		left: -16px;
		margin: auto;

	}

	b {
		padding-right: 5px;
	}

	.edit {
		width: 20px;
		height: 20px;
		cursor: pointer;
		position: absolute;
		right: 4px;
		bottom: 4px;
		top: auto;
		left: auto;
	}
}

.select_checkbox {
	width: 17px;
	height: 17px;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;

	&:hover {
		opacity: 0.85;
	}
}

.select_label {
	vertical-align: top;
	line-height: 16px;
	display: inline;
	padding-left: 15px;
	cursor: pointer;

	&:hover {
		opacity: 0.85;
	}
}

.answer_for_user {
	bottom: 0px;
	left: 0px;
	right: auto;
	height: auto;
	width: max-content;
	max-width: 370px;
	// padding-top: 15px;
	// padding-bottom: 15px;
	position: fixed;
	background: rgb(0, 109, 240);
	z-index: 555555;
	color: white;
	-webkit-align-items: center;
	align-items: center;
	display: none;
	opacity: 0;
	visibility: hidden;
	transition: all 0.9s ease-out;
	padding: 3px 5px;
	p {
		font-size: 14px;
		text-align: left;
		width: 100%;
		text-transform: lowercase;
	}
}

.showpopup {
	display: flex;
	opacity: 1;
	visibility: visible;
	// animation: showhide;
	// animation-duration: 1.5s;
	// opacity: 0;
}

@keyframes showhide {
	0% {
		opacity: 0
	}

	40% {
		opacity: 0.8
	}

	100% {
		opacity: 0
	}
}

.form_to_passwordChange {
	@include PosFixCent;
	@extend .add_info_form;
	text-align: center;
	z-index: 1;
	height: 185px;
	width: 500px;
	@extend .transitionall;
	opacity: 0;

	input[name="password_confirm"] {
		float: none;
	}
}

.form_to_belongsToChange {
	@include PosFixCent;
	@extend .add_info_form;
	height: 160px;
	opacity: 0px;
	text-align: center;
	width: 400px;

	.form_message {
		margin-bottom: 14px;
	}

	button,
	input {
		width: 260px;
		text-align: center;
		padding-left: 0px;
	}
}

.FormChangeDesc {
	@include PosFixCent;
	@extend .add_info_form;
	height: 252px;
	text-align: center;
	width: 400px;

	.form_text_area {
		margin-bottom: 10px;
		height: 135px;
	}

	.form_text_area,
	.btn_black {
		float: none;
		width: 85%;
	}
}

.edit_belongsTo {
	position: absolute;
	right: 12px;
	top: 0px;
	bottom: 0px;
	left: auto;
	opacity: 0;
	z-index: 1;
	margin: auto;
	@extend .transitionall;
}

.target_group {
	@include PosAbsCent;
	max-width: 90%;
	min-width: 32px;
	left: 5px;
	right: auto;
	height: 22px;
	display: block;
	border: 2px solid transparent;
	@extend .transitionall;
	cursor: pointer;

	&:hover {
		border-color: #7faef5;
	}

	&:before {
		content: '';
		width: 130%;
		height: 50%;
		@include PosAbsCent;
		left: -2px;
		background: $light_grey_back;

	}

	&:after {
		content: '';
		width: 90%;
		height: 130%;
		@include PosAbsCent;
		background: $light_grey_back;
	}

	.group_name {
		position: relative;
		z-index: 2;
		display: block;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		line-height: 17px;
	}
}

.darknes {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100400;
	display: none;
	opacity: 0;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
	background: rgba(0, 0, 0, .7)
}

.darknes_active {
	opacity: 1;
	display: block
}

.show_form {
	visibility: visible;
	max-width: 100%;
	z-index: 100400;
	opacity: 1;
}

.admin_page {
	.input {
		display: block;
		margin-bottom: 15px;
	}

	.form_message {
		text-align: left;
		padding-left: 10px;
	}
}

.transports {
	position: relative;
	.container{
		position: relative;
		margin-bottom: 25px;
	}
	@extend .one_block;

	.page_name {
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
		position: relative;

		.decoration_div {
			position: absolute;
			width: 100%;
			height: 23px;
			bottom: 5px;
			z-index: -1;
			box-shadow: inset -11px -43px 127px -9px white;
			-webkit-border-radius: 15px;
			border-radius: 15px;
		}

		.count_connected_vehicles {
			font-size: 16px;
		}
	}

	.transport_form {
		position: fixed;
		top: 30vh;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;

		input {
			margin: auto;
			margin-bottom: 15px;
		}

		textarea {
			margin-bottom: 15px;
			margin-left: 5px;
		}

		div {
			display: flex;
			-webkit-justify-content: space-between;
			justify-content: space-between;
			-webkit-align-items: space-between;
			align-items: space-between;
			width: 100%;
			flex-wrap: wrap;
		}

		.gas_station {
			.input {
				margin-left: 0;
			}
		}

		#checkbox_fuelingWithoutConnection {

			margin-left: 10px;
			margin-bottom: 7px;

		}

		.checkbox_label {
			width: 260px;
			display: inline-flex;
			height: 30px;
		}
	}
}

.tubs_vehicle_gasStation {
	.left_btn {
		-webkit-border-radius: 7px 0px 0px 7px;
		border-radius: 7px 0px 0px 7px;
	}

	.right_btn {
		-webkit-border-radius: 0px 7px 7px 0px;
		border-radius: 0px 7px 7px 0px;
	}
}

///client client client client client client client client client client client
.link_client {
	text-decoration: none;
	color: black;
	cursor: pointer;

	&:hover {
		border-bottom: 1px solid black;
		opacity: 0.5;
	}
}

.nodata {
	margin-top: 15px;
	font-family: $Rbold;
}

.message {
	position: fixed;
	z-index: 100500100;
	bottom: 0px;
	left: 0px;
	right: 0px;
	padding: 15px;
	opacity: 0;
	transition: opacity 0.4s ease-in;
	background: #03a6d7;

	display: flex;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;

	.close {
		fill: white;
		right: 10px;
		top: 10px;
	}

	.message_text {
		font-size: 2.2rem;
		text-align: left;
		color: white;
		opacity: 1;
		font-family: $Rbold;

	}
}

.message_hidden {
	visibility: hidden;
}

.message_visible {
	visibility: visible;
	opacity: 0.8;
}

.link_to_device {
	padding: 10px 0px;
	display: inline-block;
}

.go_back {
	display: block;
	padding-top: 15px;
	text-transform: uppercase;
	color: #525050;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.add_admin {
	.form_text_area {
		float: none;
	}

	input[name='password_confirm'] {
		margin-left: 15px;
	}
}

.current_info {
	width: 100%;
	height: auto;
	position: relative;
	padding-top: 15px;
	padding-bottom: 15px;

	h3 {
		margin-bottom: 15px;
	}

	&:after {
		@include clear;
	}

	.info_block {
		display: inline-block;
		vertical-align: top;
		overflow: auto;
		height: 500px;
		width: calc(100% - 508px);
		min-width: 320px;

		// background: #FFF;
		// padding-top: 5px;
		.info_line {
			margin-bottom: 10px;
			display: flex;
		}

		.analog_sensor {
			margin-bottom: 7px;
		}
	}

	.map {
		display: inline-block;
		vertical-align: top;
		float: right;
		max-width: 60%;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	}

	.reload {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 2;
		background: transparent;
		transition: all 0.4s ease;
		cursor: pointer;
		height: 35px;
		border-radius: 50%;

		&:hover {
			background: rgba(0, 0, 0, 0.2)
		}

		&:active {
			transform: rotate(360Deg)
		}
	}

	.status_device {
		margin-bottom: 15px;

		div {
			display: inline-block;
		}

		p {
			// display: inline-block;
			font-size: 1.6rem;
			margin-bottom: 8px;

			span {
				font-family: $Rbold;
			}
		}

		.btn_black {
			float: right;
			// vertical-align: bottom;
		}

		&:after {
			@include clear;
		}
	}

	.key_name {
		font-family: $Rbold;
		padding-right: 7px;
		vertical-align: top;
	}

	.one_sensor {
		padding: 12px;
	}

	.one_key {
		margin-bottom: 8px;
	}

	.date_for {
		padding: 15px;
		border-top: 1px solid rgba(0, 0, 0, 0.2);

		.input {
			padding-left: 0;
		}

		>div {
			text-align: center;
			margin-bottom: 15px;
		}

		text-align: center;

		.btn_black {
			/* width: 380px; */
			padding: 6px 20px;
			margin-left: 10px;
			max-width: 100%;
			font-family: $Rbold;
		}
	}

	.enter_date {
		display: inline-block;
		padding-right: 25px;
		height: 30px;
		line-height: 30px;
	}
}

.edit_device_on_transport {
	.devicelist {

		.oneLine,
		.head_line {
			height: auto;

			.cell {
				overflow: visible;

				p {
					padding-top: 0px;
					padding-bottom: 7px;
				}
			}
		}
	}
}

.droppZone {
	text-align: center;
	padding: 15px 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1200px;

	.drop_block {
		display: inline-block;
		width: 500px;
		height: 300px;
		border: 1px solid black;
		margin: 10px;
		vertical-align: top;
		padding: 15px;
		height: 400px;
		overflow: auto;
		text-align: left;
		background: #FFF;

	}

	.name_block {
		font-size: 16px;
		font-family: $Rbold;
		font-weight: 700;
		margin-bottom: 16px;
		text-align: center;
	}

	.item {
		margin-bottom: 7px;
		padding: 3px 7px;
		border: 1px solid transparent;

		&:hover {
			border-color: $blue;
		}

		span {
			margin-left: 8px;
			font-family: $Rbold;
		}
	}

	.btn_black {
		display: block;
		margin: 15px auto 0px;
	}

	.no_style_btn {
		background: transparent;
		outline: none;
		cursor: pointer;
	}

	.arrowContainer {
		.arrows_btn {
			display: inline-block;
			height: 40px;
			position: relative;
			width: 26px;
			margin: 0 5px;

			&:hover {
				opacity: 0.7;
				cursor: pointer;
			}

			.arrows {
				@include PosAbsCent;
			}
		}
	}

}

.userCan {
	padding: 15px 0px;

	h2 {
		margin-bottom: 15px;
	}

	.select_label {
		margin-bottom: 15px;
		padding-left: 0px;
		display: block;
	}

	.select_checkbox {
		margin-right: 8px;
	}
}

.edit_btn {
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M14.2 3.4l-2.1-2.1c-0.4-0.4-1-0.4-1.4 0L10 1.9l3.5 3.5 0.7-0.7C14.6 4.4 14.6 3.8 14.2 3.4z"/><rect x="4.2" y="4.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.7505 7.9038)" width="8" height="5"/><polygon points="1.8 13.6 6.4 12.6 2.9 9 "/></svg>');
	background-size: 15px;
	background-position: center;
	background-repeat: no-repeat;
	border: solid 1px #d7d9dc;
	background-color: white;
	width: 28px;
	height: 28px;
}

.edit_btn {
	cursor: pointer;

	&:hover {
		border-color: #4179f8;
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="#4179f8" d="M14.2 3.4l-2.1-2.1c-0.4-0.4-1-0.4-1.4 0L10 1.9l3.5 3.5 0.7-0.7C14.6 4.4 14.6 3.8 14.2 3.4z"/><rect fill="#4179f8" x="4.2" y="4.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.7505 7.9038)" width="8" height="5"/><polygon fill="#4179f8" points="1.8 13.6 6.4 12.6 2.9 9 "/></svg>');

	}
}

.primari_btn {
	border-radius: $border-radius;
	background-color: $blue;
	padding: 8px 20px 8px;
	color: white;
	text-decoration: none;
	cursor: pointer;
	border: 1px solid white;

	&:hover {
		color: $blue;
		background: #FFF;
		border: 1px solid $blue;
	}

	@include not_allowed;
	@include btn_text_style;
}

.showSocketInfo {
	@extend .primari_btn;
	border-radius: 3px;
	background: #728dae;
	padding: 5px 10px 6px;
	height: 26px;
	margin: auto;
	@include collapsedText;
}

.onCalibration {
	display: none;
}

.calibrations,
.existings_sensorType {
	.nameForm {
		margin-bottom: 15px;
		margin-right: 15px;
		display: inline-block;
	}

	.sensor_types {
		display: inline-block;
		padding-bottom: 0px;

		button[name='CreateSensorType'] {
			display: inline-block;
		}

		.counter_switch {
			display: flex;

			.text_info {
				padding-right: 10px;
			}
		}

		.name_select_list {
			padding-bottom: 10px;
		}

		.form_text_area {
			margin-bottom: 15px;
			height: 150px;
		}

		.create {
			position: absolute;
			z-index: 5;
			left: 0px;
			right: 0px;
			width: 730px;
			padding: 15px;
			background: white;
			margin: auto;
			border: 1px solid $grey_line;
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
			max-height: calc(100vh - 120px);

			// overflow: auto;
			.onCalibration {
				display: block;
				width: 25px;
				right: -25px;
				position: absolute;
				top: -25px;
				cursor: pointer;
				@extend .transitionall;

				&:hover {
					transform: rotate(360deg) scale(1.2);

				}
			}
		}
	}

	.head_group {
		display: flex;
		position: relative;
		-webkit-align-content: space-between;
		align-content: space-between;
		justify-content: space-between;
		-webkit-align-items: center;
		align-items: center;
	}
}

.templates {
	position: relative;
	padding-top: 15px;

	h2 {
		margin-bottom: 10px;
	}

	.templates_list {
		padding-top: 15px;
		padding-bottom: 15px;

		button {
			margin-right: 15px;
			margin-bottom: 7px;
		}
	}

	.reportInfo {
		display: block;
		margin: 15px auto;
		max-width: 500px;

		p {
			font-family: $RLight;
		}

		.head_block {
			font-family: $Rbold;
			font-size: 1.5rem;
		}

	}
}

.copy_widget {
	position: fixed;
	z-index: 15;
	top: calc(100vh - 89px);
	left: calc(100vw - 300px);
	width: 270px;
	border: 1px solid #eaeaea;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	padding: 10px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	background: #FFF;
	text-align: center;

	p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-bottom: 10px;
	}

	.copyClose {
		width: 15px;
		height: 15px;
		position: absolute;
		right: -12px;
		top: -12px;
		cursor: pointer;
	}

	@include transitionall;
}

.flex_container {
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	margin-bottom: 15px;

	input {
		margin-top: 0px !important;
	}
}

.NotFound {
	h3 {
		padding: 25px 0px;
	}
}

.secondary_btn {
	padding: 7px 20px 8px;
	border-radius: $border-radius;
	border: solid 1px #d7d9dc;
	background-color: #ffffff;
	color: #0e0e0e;
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}

	@include btn_text_style;
}

.btn_close {
	background: none;
	border: none;
	cursor: pointer;

	.symbol {
		transition: all 0.4s ease;
	}

	&:hover {
		.symbol {
			fill: #4179f8;

		}
	}
}

.help_menu {
	position: fixed;
	top: 150px;
	right: 0px;
	z-index: 50;
	width: 240px;
	background: #FFF;
	border: 1px solid $border_block;
	border-radius: 14px 0px 14px 0px;
	padding: 10px;
	visibility: hidden;
	opacity: 0;
	@include transitionall;

	li {
		list-style: none;
		padding-top: 4px;
		padding-top: 3px;
		border-bottom: 1px solid $grey_line;
		@include transitionall;

		&:hover {
			cursor: pointer;
			background: $blue;
		}
	}
}

.logCell {
	padding-right: 15px;
}

.logs {
	.content {

		.input,
		.btn_black,
		.hint_container {
			display: inline-block;
			vertical-align: top;

		}

		.input {
			margin-right: 10px;
		}

		.btn_black {
			height: 30px;
			padding: 5px 20px;
			vertical-align: bottom;
			margin-bottom: 1px;
		}
	}

	.no_data {
		padding: 25px;
	}

	.one_filter {
		display: inline-block;
		vertical-align: top;
		margin-right: 10px;

		>p {
			padding-bottom: 10px;
		}
	}
}

.edit_user_logging {
	display: inline-block;
	margin-bottom: 15px;

	.btn_black {
		margin-right: 10px;
	}
}

.tooltip {
	position: relative;

	.tooltiptext {
		position: absolute;
		visibility: hidden;
		top: -10px;
	}

	&:hover {
		.tooltiptext {
			visibility: visible;
		}
	}
}

.one_switch {
	display: flex;
	height: 35px;

	.name_switch {
		padding-right: 15px;
	}

	.show {
		z-index: 100501;
	}

	-webkit-align-items: center;
	align-items: center;
}

.time_input_container {
	display: inline-flex;
	-webkit-align-items: center;
	align-items: center;

	label {
		padding-right: 10px;
	}
}

.page_name_client{
	span{
			padding-left: 7px;
		}
}
.userPage {
	.page_name_client {
		width: auto;
		font-size: 21px;
		margin-bottom: 15px;

	}

	.changePass {
		display: block;
		width: 290px;
		vertical-align: top;
		margin-bottom: 20px;
		min-width: 320px;

		.input {
			margin-bottom: 15px;
		}
	}
}

.message_on_userPage {
	padding-bottom: 15px;

	.form_text_area {
		margin-bottom: 10px;
	}

	.btn_black {
		display: block;
	}
}

.statistic_by_device {
	span {
		font-weight: bold;
		display: inline-block;
		width: 40px;
	}

	margin-bottom: 25px;
}

.self_page {
	.page_name {
		margin-bottom: 15px;
		span{
			text-transform: uppercase;
		}
	}

	.form_message {
		margin-bottom: 0;
	}
}

.reversed_realSensor {
	filter: grayscale(1);
}

.vehicle_owner {
	position: relative;
	display: inline-block;


	.btn_edit_vehicle_owner {
		right: -30px;
		left: auto;
		top: -12px;
	}

	.form_edit_vehicle_owner {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		background: #FFF;
		display: flex;
		width: 450px;

		.close {
			top: 0px;
			height: 29px;
		}

		.hint_container {
			display: inline-block;
			margin-right: 15px;
		}
	}
}

.ignition_correction_form {
	padding-top: 15px;

	.nameForm {
		margin-bottom: 7px;
	}

	.time_input_container {
		margin-bottom: 16px;
		display: block;
	}

	.time_input_label {
		display: block;
	}

	.select_label {
		padding-left: 0;
		margin-bottom: 10px;
		display: block;

		.select_checkbox {
			margin-right: 7px;
		}
	}
}

.remove_data_for_sensor_by_period{
	@extend .ignition_correction_form;
	.input{
		margin-bottom: 16px;
		width: 205px;
		-webkit-border-radius: 4px;
		        border-radius: 4px;
	}
}

.transport_page {
	.page_name_transport {
		display: block;
		padding-bottom: 10px;
		padding-top: 25px;
		.license_plate{
			color: $grey_text;
			font-weight: lighter;
		}
		.id_in_head {
			font-size: 1.6rem;
			font-weight: normal;
		}
	}
}

.fast_command {
	display: flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	@extend .one_block;

	.re_request_data {
		display: inline-block;
		width: auto;
		background: transparent;
		padding: 0;

		.time_input_container {
			display: inline-flex;
		}

		a {
			display: none;
		}

		.date_for {
			border-top: none;
			margin-bottom: 0;
			padding-left: 0;

			&>div {
				margin-bottom: 0;
				display: flex;
			}
		}

		h3 {
			text-align: left;
		}
	}

	.command_toDevice_in_mount {
		padding: 15px 0px;
		display: inline-flex;
		-webkit-align-items: flex-end;
		align-items: flex-end;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}
}

#current_info {
	// border-bottom: 1px solid $black;
}

.modal_install_template_report {
	@include PosFixCent;
	z-index: 100500;
	width: 500px;
	height: 550px;
	padding-bottom: 30px;
	background: #fdff6e;
	border: 3px solid $blue;
	-webkit-border-radius: 2px;
	border-radius: 2px;

	h4 {
		text-align: center;
		padding-top: 10px;
	}

	.report_text {
		padding: 12px 15px;
		overflow: auto;
		max-height: 430px;
		margin-bottom: 15px;
		@extend .scroll;
	}

	.report_line {
		margin-bottom: 12px;
		text-style: italic;
	}

	.primari_btn {
		display: block;
		margin: auto;
		width: 75px;
	}
}

.no_data {
	margin-top: 20px;
}

.trailer_form {
	padding: 25px 15px;
	text-align: left;

	label {
		display: flex;
		margin-bottom: 15px;
		align-items: flex-end;
		justify-content: space-around;

		span {
			display: inline-block;
			width: 185px;
		}
	}

	.input {}
}

.trailer_form_footer {
	text-align: right;
	padding-top: 15px;
	padding-left: 15px;
	padding-right: 15px;
}

.trailers_info {
	// @extend .one_block;

	padding-top: 25px;

	.page_name {
		display: inline-block;
		padding-top: 0;
	}

	.add_trailer_btn {
		// margin-top: 25px;
		height: 38px;
	}

	.container_input_and_button {
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}

}

.trailers_list {
	width: 100%;
	@extend .default_table_style;

	.cell {
		width: 100%;

	}

	.cell_name {
		min-width: 240px;
	}

	.cell_serialId {
		min-width: 200px;
	}

	.cell_trailer_type {
		min-width: 180px;
	}

	.cell_trailer_width {
		min-width: 100px;
	}

	.page_name {
		margin-bottom: 20px;
	}

	.cell_id {
		min-width: 80px;
	}
}

.sub_header {
	display: flex;
	margin-bottom: 15px;

	h2 {
		display: inline-block;
		margin-right: 15px;
	}
}

.add_server_form_content {
	.wrap {
		display: inline-block;
	}

	.input,
	.radioP {
		margin-bottom: 10px;
	}

	.radioP {
		input {
			margin-right: 8px;
		}
	}

	.full_width {
		width: 100%;
	}

	.form_text_area {
		width: 100%;
		height: 96px;
	}
}

.add_device_form_content {
	@extend .add_server_form_content;

	input[type='checkbox'] {
		width: 15px;
		margin-right: 15px;
		margin-bottom: 0;
	}

	.checkBoxWrapper {
		display: flex;
		margin-bottom: 15px;
		-webkit-align-items: center;
		align-items: center;
	}
}

.servers_on_translation {
	margin-bottom: 15px;

	.search_input {
		margin-bottom: 15px;
	}
}

.devices_on_translation {
	.search_input {
		margin-bottom: 15px;
	}
	.cell_name {
		a {
			color: #525050;
		    text-decoration: none;
	    }
	}
}

.userCapability_form {
	.nameForm {
		margin-bottom: 15px;
	}
}

.user_capabilities {
	margin-bottom: 15px;
}

.additional_reports_block {
	.preloader {
		left: 0;
		right: auto;
	}

	padding-top: 15px;

	.block_name {
		margin-bottom: 15px;
	}

	p {
		margin-bottom: 8px;
	}

	label {

		input {
			margin-right: 10px;
		}
	}
}

.showQR {
	float: right;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #dfe6ec;
	border-radius: 2px;
	background: #FFF;
}

.qr_code_container {
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
	height: 329px;

	div {
		box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .14), 0 6px 10px 0 rgba(0, 0, 0, .098), 0 1px 18px 0 rgba(0, 0, 0, .084);
		position: relative;

		canvas {
			@include PosAbsCent;
		}
	}
}

.edit_real_sensor {
	padding: 25px 25px 25px !important;
	text-align: center;

	.wrap {
		display: inline-block;
		margin-bottom: 15px;
		text-align: left;
	}
}

.simple_select_list {
	width: inherit;
	height: inherit;
	border-radius: 4px;
	position: relative;
	padding: 0px 0px 0px;
	transition: width 0.2s ease;
	min-width: max-content;
	max-width: 100%;
	padding-right: 15px;
	position: relative;
	background: #FFF;

	&:active {
		.selected_item {
			background: #d7d9dc;
		}
	}

	.selected_item {
		height: inherit;
		width: inherit;
		border-radius: 4px;
		background-color: white;
		border: solid 1px #d7d9dc;
		position: relative;
		padding-right: 16px;
		padding-left: 16px;
		text-align: left;
		color: #9aa0a7;
		/* margin-bottom: 3px; */
		z-index: 2;
		position: relative;
		border-radius: 4px;
		background: #FFF;
		max-width: 100%;
		font-size: 14px;

		.triangle {
			position: absolute;
			right: 4px;
			height: 4px;
			line-height: 4px;
			@include PosAbsVertical;
		}
	}

	.list {
		visibility: hidden;
		height: inherit;
		width: inherit;
		overflow: hidden;
		position: absolute;
		left: 0px;
		padding-top: 7px;
		padding-bottom: 7px;
		transition: height 0.4s ease-in-out;
		background: inherit;
		max-width: 100%;
		border-left: 1px solid #d7d9dc;
		border-bottom: 1px solid #D7D9DC;
		border-right: 1px solid #D7D9DC;

		@extend .scroll;

		li {
			list-style: none;
			display: flex;
			min-height: 35px;
			padding-right: 16px;
			padding-left: 16px;
			align-items: center;
			cursor: pointer;

			&:hover {
				background: $blue;
				font-family: $Rbold;
				font-weight: 700;
			}
		}
	}

	.list_open {
		visibility: visible;
		height: auto;
	}
}

.transmit_period_block {
	margin-bottom: 10px;
}

.transmit_period_block_name {
	margin-bottom: 7px;
}

.react-datetime-picker__wrapper {
	border: none;
}



.reset_btn {
	height: 22px;
	width: 22px;
	background-color: transparent;
	right: 5px;
	opacity: 1;
	visibility: visible;
	transition: all 0.2s ease-out;
	position: absolute;
	margin: auto;
	top: 0px;
	bottom: 0px;
	z-index: 1;
	cursor: pointer;
}


.select_list {
	z-index: 2;
}

.callEdit {
	vertical-align: bottom;
}

.sort_arrow{
	display: inline-block;
	background: none;
	cursor: pointer;
	float: right;
}

.get_tag_pro_device_id{
	position: absolute;
	right: 14px;
    top: 33px;
    z-index: 10;
    width: 136px;
    font-size: 12px;
    padding: 6px 7px;
}

.clear_cache_block{
	padding-top: 15px;
	.primari_btn{
		margin-bottom: 7px;
	}
	.clear_cache_block_name{
		margin-bottom: 15px;
		font-weight: 600;
		// background: red;
	}
	.time_input_container{
		margin-bottom: 10px;
	}
}

.centered_message_text{
	width: 80%;
	height: 23px;
	font-size: 20px;
	text-align: center;
	@include PosAbsCent;
}

.auth_in_app_from_link_page{
	padding: 25px;
	text-align: center;
	.form_text_area{
	    width: 700px;
	    max-width: 100%;
	    height: 300px;
	}
	.input_label{
		display: flex;
		margin-bottom: 15px;
		-webkit-align-items: center;
		        align-items: center;
		p{
			margin-right: 15px;
		}
	}
	.btn_go_back{
		@extend .small_btn_black;
	}
}

.app-version-div{
	position: fixed;
	z-index: 100501;
	bottom: 0;
	left: 0;
	font-size: 9px;
	text-shadow: white 1px 0 3px;
	color: #8e8e8e;
	display: inline-block;
	opacity: 0.7;
	cursor: default;
}

.confirm_message_container{
	padding: 15px 20px;
	font-size: 16px;
	background: #efefef;
	display: flex;
	text-align: center;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.toogle_show_more_in_mounted_device{
	padding: 7px;
	background: $blue;
	cursor: pointer;
	color: white;
	display: inline-flex;
	.vertical_arrows{
		transform: rotate(90deg);
		width: 15px;
		height: 15px;
		margin-left: 15px;
		path{
			fill:white;
		}
	}
}

.mapping_info_container{
	display: flex;
	-webkit-align-items: space-between;
	        align-items: space-between;
	.sensorInfo{
		width: 50%;
	}
}
.mapping_time_line_container {
	width: 50%;
	.btn_item_in_time_line {
		padding-top: 7px;
		padding: 7px 10px;
		-webkit-border-radius: inherit;
		border-radius: inherit;
		outline: none;
		border: 1px solid black;
		background: rgb(112, 128, 144);
		color: white;

	}

	.time-line-ctnr {
		// width: 50%;
		.time-line > li{
			.time-line-item{
				display: flex;
				-webkit-align-content: center;
				        align-content: center;
				flex-direction: row-reverse;
				-webkit-align-items: center;
				        align-items: center;
			}
		}
	}
}

.correction_block{
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	    margin-bottom: 15px;
}

.edit_administrator{
	padding: 20px;
	.input, .btn_black{
		margin-bottom: 20px;
		display: block;
	}
	.form_text_area{

	}
	.btns_edit_administrator{
		margin-bottom: 15px;
		.btn_black{
			display: inline-block;
			margin-right: 15px;
		}
	}
	.form_change_admin_pass{
		margin-bottom: 25px;
	}
}

.retranslation_server_page{
	padding-top: 20px;
	.server_info{
		p, h4{
			margin-bottom: 7px;
		}
	}
	.retranslate_list{
		p{
			padding-left: 15px;
		}
	}
}






//preloader
.lds-default {
  display: inline-block;
  @include PosAbsCent;
  width: 80px;
  height: 80px;

}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: $blue;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

//preloader

@import 'device.scss';
@import 'transportProfile.scss';
@import 'switch_input.scss';
@import "media.scss";
@import "routeDetail.scss";
@import 'pop_up.scss';
@import 'selectListOrSensor.scss';
// @import 'virtual.css';
@import 'notifications.scss';
@import 'notif_menu.scss';
@import 'whatIsNewPage.scss';
