/*routeDetail*/
@import "mixin/mixin.scss";
@import "fonts.scss";
.preloader{
	@include transitionall;
	@include PosAbsCent;
	font-size: 17px;
	font-family: $Rbold;
	p{
		@include PosAbsCent;
		width: 100%;
		text-align: center;
		height: 17px;
		text-transform: uppercase;
	}
}
.graphic_admin{
	position: relative;
	// padding-top: 20px;
}
.routeDetailPage{
	position: relative;
	height: calc(100vh - 46px);
	
	
	.preloader{
		z-index: 2;
		top: auto;
		bottom: 150px;
		p{
			height: 80px;
			line-height: 80px;
			width: 300px;
		}
	}
	.transport_list{
		background-color: #f5f5f5;
		list-style: none;
	    width: 100%;
	    height: 334px;
	    overflow-y: auto;
	    padding: 22px 23px 22px 12px;
	    @extend .scroll;
	    li{
	    	b{
	    		margin-right: 15px;
	    		color: black;
	    	}
	    	padding: 9px 23px 9px 12px;
	    	cursor: pointer;
	    	color: $grey_text;
	    	height: 35px;
	    	width: 100%;
	    	background: transparent;
	    	-webkit-transition: border-color 0.2s ease;
	    	   -moz-transition: border-color 0.2s ease;
	    	    -ms-transition: border-color 0.2s ease;
	    	     -o-transition: border-color 0.2s ease;
	    	        transition: border-color 0.2s ease;
	    	border: 1px solid transparent;
	    	border-radius: 3px;
	    	border-bottom-color: rgb(223, 230, 236);
	    	position: relative;
	    	&:before{
	    		content: '';
	    		@include PosAbsVertical;
	    		left: 0px;
	    		width: 12px;
				height: 35px;
	    		background-color: rgb(245, 245, 245);
	    	}
	    	&:after{
	    		content: '';
	    		width: 100%;
	    		position: absolute;
	    		height: 1px;
	    		top: -2px;
	    		display: block;
	    		opacity: 0;
	    		background-color: rgb(245, 245, 245);
	    	}
	    	svg{
	    		opacity: 0;
	    		@include transitionall;
	    		position: absolute;
			    top: 0px;
			    bottom: 0px;
			    height: 11px;
			    margin: auto;
			    right: 100%;
			    right: 12px;
	    	}
	    	&:last-child{
	    		border-bottom: 1px solid transparent;
	    	}
	    }
	    .activeStyle{
	    	&:before{
				display: none;
	    	}
	    	&:after{
	    		opacity: 1;
	    	}
	    	background: white;
	    	border-color: rgb(223, 230, 236);
	    	border-bottom: 1px solid rgb(223, 230, 236)!important;
	    	svg{
	    		opacity: 1;
	    	}
	    }
	}  
	.pop_up_footer{
	   		padding: 18px 20px;
	   		.primari_btn{
	   			margin-left: 15px;
	   		}
	   		.primari_btn, .secondary_btn{
	   			float: right;
	   		}
	}
	.transport_search_form{
			display: block;
			width: 340px;
			max-width: 100%;
			margin: 20px auto;
			.input_in_popup{
				width: 100%;
				display: block;
				
			}
	}
	
}
.plashka{
	min-width: 312px;
	border-radius: 2px;
	background-color: #ffffff;
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.23);
	position: relative;
	z-index: 2;
	display: inline-block;
	.license_plate, .name{
		font-family: $Roboto;
  		font-size: 16px;
  		letter-spacing: 0.2px;
	}
	.license_plate{
		font-family: $Rbold;
		color: #0e0e0e;
		display: inline-block;
		padding-left: 12px;
		padding-right: 12px;
	}
	.name{
		color: #9AA0A7;
	}
	.one_vehicle{
		height: 54px;
		display: flex;
		align-items: center;
		padding-right: 14px;
	}
}
.back{
	height: 100%;
	width: 54px;
	// border-right: 1px solid $grey_line;
	svg{
		opacity: 0.7;
	}
}

.for_mobile_des{
	display: none;
}
.graph_container{
	position: absolute;
	width: 99vw;
	z-index: 2;
	// bottom: 0px;
	left: -25px;
	right: 0px;
	top: 45px;
	margin: auto;
	background: #F5F5F5;
	height: 322px;
	padding-left: 56px;
	line-height: 0px;
	overflow: hidden;
}
.graph_tumbler{
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 4;
	height: 100%;
	width: 56px;
	display: block;
	background: #1d2329;
	padding-top: 40px;
	overflow-x: hidden;
	overflow-y: auto;
	@include transitionall;
	@extend .scroll;
	&:hover{
		width: 233px;
		min-width: 223px;
		width: max-content;
		-webkit-box-shadow: 1px 3px 25px -1px rgba(0,0,0,0.75);
		-moz-box-shadow: 1px 3px 25px -1px rgba(0,0,0,0.75);
		box-shadow: 1px 3px 25px -1px rgba(0,0,0,0.75);
	}
	

}
.tumbler_button{
	background: #1d2329;
	color: white;
	display: block;
	width: 100%;
	min-width: 223px;
	text-wrap: nowrap;
	text-align: left;
	border-bottom: 1px solid #0E0E0E;
	height: 56px;
	line-height: 56px;
	padding-left: 15px;
	overflow: hidden;
	&:hover{
		background-color: #34393e;
		
	}
	.btn_name{
		padding-left: 21px;
		padding-right: 15px;
	}
	svg{
		vertical-align: middle;
	}
}
.filters_by_graph{
	background-color: #ebf2f8;
	width: calc(100% - 270px);
	padding: 7px 14px 7px  24px;
	display: inline-block;
	vertical-align: top;
	&:after{
		@include clear;
	}
	.filter_events{
		float: right;
		span{
			    display: inline-block;
			   line-height: 19px;
    			vertical-align: top;
			}
		
	}
}
.zoom_container{
	width: 270px;
	display: inline-block;
	padding: 11px 36px 15px;
	position: relative;
}
.zoom_btn{
	color: #424F5B;
}
.minus, .plus{
	position: absolute;
	z-index: 1;
	
	bottom: 13px;
	line-height: 12px;
	svg{
		display: inline;
	}
}
.plus{
	right: 12px;
	top: 6px;
}
.minus{
	left: 12px;
	top: 1px;
}

@import 'defDecision.scss';
.graph_container{
	.container_for_select{
		display: inline-block;
		height: 25px;
	    line-height: 25px;
		.triangle{
			@include PosAbsVertical;
			right: 5px;
			z-index: 1;
				&:before{
				content: '\25B2';
			    display: block;
			    position: absolute;
			    color: white;
			    top: 8px;
			    width: 0px;
			    height: 0px;
			    left: -6px;
			    text-shadow: 0px -2px 1px #969696;
			    background: transparent;
			}
		}
		.deactive{
			.triangle{
			&:before{
				display: none;
			}
		}
			
		}
		.hidden_select_list_NODATA{
			    top: 33px;
			    left: -79px;
			    z-index: 2;
			    border: solid 1px #c9cbce;
		} 
	}
}
.select_name{
	color: #828D97;
	font-size: 13px;
	padding-right: 5px;
	display: inline-block;
}
.graph_container{
	.drivers_list, .date_list{
		display: inline-block;
		background: #ebf2f8;
		margin-bottom: 0px;
		.transport_search{
			padding-left: 33px;
			padding-right: 14px;
			border-bottom: 1px solid $grey_line;
			height: 36px;
			-webkit-border-radius: 0px;
			        border-radius: 0px;
		}
		.hidden_list{
			list-style: none;
			background: #FFF;
			top: 27px;
			left: -25px;
			min-width: 190px;
			padding-bottom: 7px;
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	  		border: solid 1px #c9cbce;
			li{
				padding: 11px 18px 11px 23px; 
				line-height: 14px;
				.no_style_btn{
					font-size: 14px;
				}
				&:hover{
					background-color: #f5f5f5;
				}
			}
		}
		.selected_value{
			
		}
	}
	.chart_values{
		position: absolute;
		bottom: 5px;
		left: 6px;
		width: 100%;
		font-family: $Roboto;
		height: 16px;
		z-index: 2;
		line-height: initial;
		.one_value{
			padding-right: 15px;
		}
	}
	.events_on_chart {
		&:hover{
			cursor: pointer;
		}
	}
}
	
.date_list{
	.hidden_list{
	left: -34px;
		
	}
}
.filters_by_graph{
	.select_list{
		display: inline-block;
	}
}
.SelectListDate_container{
	display: inline-block;
}
.calendar_range_container, .range_input{
	width: 548px;
	right: -548px;
	top: 32px;
	z-index: 3;
	position: absolute;
	padding: 26px 36px;
	background: #FFF;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  	border: solid 1px #c9cbce;
  	border-radius: 2px;
}
.range_input{
	.inputs {
		display: flex;
		margin-bottom: 15px;
	}
	.input{
		width: 200px;
	}
	.time_input_container{
		display: inline-block;
	}

}
.line {
	stroke: rgb(63, 251, 167);
 	stroke-width: 3px;

}
.chart{
	position: relative;
	>svg{
		position: absolute;
		top: 0px;
		left: 0px;
		text{
			font-family: $Roboto;
		}
	}
	.hidden_div{
		visibility: hidden;
		line-height: 0px;
   	 	max-height: 0px;
   	 	overflow: hidden;
	}
	.axisTime{
		line{
			stroke: rgb(215, 217, 220);
			stroke-opacity: 1;
		}
		text{
			fill: rgb(66, 79, 91);
  			font-size: 11px;
 			line-height: 17px;
 			y:-16;
 			x: 10;
  			// color: rgb(66, 79, 91);
		}
	}
	.graphic_name{
			width: 500px;
			fill: rgb(14, 14, 14);
  			font-size: 14px;
 			line-height: 17px;
 			span{
 				color: rgb(154, 160, 167);
 			}
	}
}
.focus{
	font-size: 12px;
  	
  	color: rgb(65, 121, 248);
  	width: 100px;
  	span{
  		color: rgb(154, 160, 167);
  	}
}
#canvas{
}
.chartContainer{
	@extend .scroll;
}
.tumbler_filter{
	display: none;
}
.tumbler{
		position: absolute;
	    left: 2px;
	    top: 3px;
	    width: 50px;
	    height: 34px;
		background: url("/images/table-illustration@2x.png");
    	background-size: 48px 33px;
    	background-repeat: no-repeat;
    	z-index: 5;
    	background-position: center;
}
.route_table{

	padding-top: 10px;
	position: relative;
	// height: inherit;
	width: content-size;
	height: 283px;
	overflow-x: auto;
	.event_in_table{
		width: 700px;
	}
	.string_lines{
		height: 14px;
		margin-bottom: 7px;
		white-space: nowrap
	}
	span{
		display: inline-block;
		padding-right: 10px;
		height: 14px;
	}
	i{
		padding-right: 7px;
	}
	
}
.calculated_values{
	position: fixed;
	top: 138px;
	left: 0px;
	width: 360px;
	min-height: 50px;
	height: auto;
	z-index: 5;
	line-height: 1.8px;
	background-color: rgba(255, 255, 255, 0.75);
	padding: 15px;
	-webkit-border-radius: 2px;
	        border-radius: 2px;
	border: 1px solid $grey_line;
	-webkit-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.23);
	        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.23);
	p{
		line-height: 1.6rem;
	}
	.date{
		font-family: $Rmedium;
	}
	.value_name{
			font-family: $Rmedium;
			display: inline-block;
			padding-right: 10px;
	}
	.grey_text{
		display: inline-block;
		padding-left: 10px;
		color: $grey_text;
	}
}
.date_box{
	position: absolute;
	top: 10px;
	color: $blue;
	font-size: 12px;
	z-index: 1;
	opacity: 0;
}
@media screen and (max-width: 767px){
	.graph_container{
		overflow: visible;
		.tumbler{
			width: 36px;
			height: 36px;
			background-size: 33px 31px;
			left: 0px;
			top: -35px;
			background-color: rgba(12, 12, 12, 0.69);
		}
	}
	.plashka{
		top: 10px;
		min-width: 197px;
		width: 50%;
		height: auto;
		min-height: 35px;
		.license_plate, .name {
			display: inline-block;
			font-size: 1.4rem;
			min-width: 100px;
			padding-left: 41px;
			padding-right: 5px;
			padding: 5px 0px;
		}
	}
	.back{
		width: 36px;
		height: 36px;
		&:after{
			    height: calc(100% + 10px);
		}
	}
	.del_btn_container{
		width: 36px;
		height: 36px;
		.black_delete_btn{
			@include PosAbsCent;
		}
	}
	.graph_tumbler{
		width: 36px;
		z-index: 9;
	}
	.tumbler_button{
		padding-left: 7px;
		height: 36px;
		line-height: 36px;
	}
	.tumbler_filter{
		position: absolute;
		left: 0px;
		top: 0px;
		width: 36px;
		height: 36px;
		display: block;
		z-index: 10;
	}
	.point_on_graph{
		transform: rotate(90deg);
		.symbol{
			fill: white;
		}
	}
	.graph_container{
		padding-left: 36px;
		height: 235px;
		background: green;
		.container_for_select{
			height: auto;
			line-height: initial;
		}
	}
	.filters_by_graph{
		width: 100%;
		max-width: 284px;
		padding: 39px 0px 0px 10px;
		background: #1d2329;
		position: absolute;
		left: -100%;
		transition: left 0.4s ease-in-out;
		z-index: 8;
		.filter_events{
			float: none;
			height: 30px;
			>span{
				color: #FFF;
			}
		}
		.drivers_list, .date_list{
			background: #1d2329;
		}
		.graph_container{
				padding-left: 36px;
		}
		.drivers_list, .date_list{
			.hidden_list{
				left: 0px;
				top: 24px;
			}
		}
	}
	.select_name{
		min-width: 80px;
		color: #FFF;
		display: inline-block;
		margin-bottom: 10px;
	}
	.for_mobile_des{
		display: block;
	}
	.select_list .selected_value{
		color: #FFF;
	}
	.zoom_container{
		width: 100%;
		max-width: 284px;
		background: #1d2329;
		position: absolute;
		left: -100%;
		transition: left 0.4s ease-in-out;
		z-index: 8;
		.zoom_btn{
			g {
				fill: #FFF;
			}
		}
	}
	.chart{
		// display: none;
	}
	.calculated_values{
		position: fixed;
		top: 100px;
		width: 150px;
		height: auto;
		z-index: 5;
		background: #FFF;
		-webkit-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.23);
		        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.23);
		
	}

}
@import "index.scss"