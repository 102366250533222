// transportProfile.scss
@import "mixin/mixin.scss";
@import "fonts.scss";
.listOfVirtualSensor{
	display: inline-block;
	margin-right: 25px;
	position: relative;
	.delete{
		right: 5px;
		width: 15px;
		left: auto;
	}
}
.showTextArea{
	margin: 10px 0px;
}
.block_btn{
	margin-top: 5px;
}
.addRealSensor{
	padding: 15px 0px;
	overflow: hidden;
	position: relative;
	.add_realSensor_form{
		position: relative;
		.close{
			top: 0px;
		}
		.input{
			display: block;
		}
	}
}
.SensorsInfoOnDevice{
	padding: 10px 0px;
}
.nameOfList_multisensors{
	margin-bottom: 15px;
}
.updateCalibration{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	margin: auto;
	width: 500px;
	max-width: 100%;
	background: #FFF;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    padding: 15px 15px 0px;
    z-index: 5;
    .input, .btn_black{
    	display: block;
    }
    .form_name{
    	font-family: $Rbold;
    	font-size: 14px;
    	padding-bottom: 10px;
    }
    .close{
    	left: auto;
    	right: -15px;
    	top: -15px;
    }
}
.transport_profile{
	height: auto;
	width: 100%;
	@extend .one_block;
	._header{
  		// background-color: rgb(255, 255, 255);
  		padding: 18px 20px 0px;
  		border-bottom: 1px solid rgb(234, 234, 234);
  		@include clear;
  		.secondary_btn{
  			float: right;
  			vertical-align: top;
  		}
  		.transport_name{
  			vertical-align: top;
  			display: block;
  				font-size: 16px;
				line-height: 18px;
				color: rgb(14, 14, 14);
				height: 30px;
				line-height: 30px;
  			.plate{
				font-weight: bold;
				text-transform: uppercase;
				padding-right: 15px;
  			}
  		}
  		.vehicle_id{
  			 font-size: 14px;
  			 padding-top: 1px;
  			  color: rgb(130, 141, 151);
  		}
  		.tabs{
  			background: transparent;
  			padding-bottom: 8px;
  			opacity: 0.5;
  			color: rgb(14, 14, 14);
  			border-bottom: 3px solid transparent;
  			margin-top: 24px;
  			margin-right: 40px;
  			@include transitionall;
  		}
	}
	.sensor_name{
		  font-size: 16px;
		  font-weight: bold;
		  letter-spacing: 0.4px;
		  color: rgb(14, 14, 14);
		  vertical-align: top;
		  margin-right: 18px;
	}
	.sensor_name_contrainer{
	    line-height: 18px;
	    min-height: 22px;
	}
	.profile_container{
		padding: 31px 25px 85px 24px;
		overflow-y: scroll;
		height: auto;
		width: 760px;
		max-width: 100%;
		.one_block{
			margin-bottom: 3.6rem;
		}

	}
	.profile_container::-webkit-scrollbar {
	   width: 6px;
	}

	//Track
	.profile_container::-webkit-scrollbar-track {
	    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	    border-radius: 11px;
	    // margin-top: 4px;
	    margin-bottom: 10px;
	}

	//Handle
	.profile_container::-webkit-scrollbar-thumb {
	    -webkit-border-radius: 10px;
	    border-radius: 10px;
	    background: #9aa0a7;
	    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	}
	.profile_container::-webkit-scrollbar-thumb:window-inactive {
		background: #9aa0a7;
	}
}
.device_settings{
	@extend .one_block;
	.editor_contrainer{
		max-width: 500px;
	}
	.profile_container{
		padding: 0px 25px 36px 24px;
	}
	.nameForm{
		padding-left: 24px;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 1.6rem;
		// font-weight: bold;
		font-family: $Rmedium;
	}
}

.editor_contrainer{
	border-bottom: 1px solid  rgb(245, 245, 245);
	display: flex;
	align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
    position: relative;
    opacity: 1;
    font-size: 16px;
    @include transitionall;
	.text_info{
		font-size: 14px;
  		color: rgb(14, 14, 14);
  		display: inline-block;
  		max-width: 75%;
	}
	.input_container{
		margin-right: 0px;
		height: 19px;
		line-height: 19px;
		span{
			height: inherit;
			line-height: inherit;
			vertical-align: top;
			color: rgb(154, 160, 167);
		}
	}
	.editor_input{
		background: transparent;
		display: inline-block;
		font-size: inherit;
		width: 90px;
   		text-align: right;
   		vertical-align: top;

	}
	.edit_btn{
		border:none;
		background-color: transparent;
		height: 20px;
		margin-left: 36px;
	}
	.editor_form{
		position: absolute;
		bottom: 0px;
		top: 0px;
		margin: auto;
		opacity: 0;
		visibility: hidden;
		right: 27px;
		display: block;
		width: 210px;
		height: 95%;
		line-height: 95%;
		background: #FFF;
		border-radius: 2px;
		box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
		text-align: center;

		padding: 10px;
		@include transitionall;
	}
	.form_input{
		    width: 70px;
    		height: 35px;
    		border-radius: 4px;
    		background-color: white;
    		border: solid 1px #d7d9dc;
    		margin-bottom: 14px;
    		padding-left: 12px;
    		font-size: 13px;
    		margin-right: 10px;
    		display: inline-block;
            -moz-appearance:textfield;
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
			    -webkit-appearance: none;
			}
	}
}
.editor_container_switch{
	padding-right: 7px;
	@extend .editor_contrainer;
}
.counter_container{
	padding: 16px 4px 14px;
	border-bottom: #F5F5F5 1px solid;
	display: flex;
	align-items: center;
	justify-content: space-between;
    .counter_switch{
	    line-height: 18px;
	    min-height: 22px;
    }
    .text_info{
		font-size: 14px;
  		color: rgb(14, 14, 14);
  		display: inline-block;
  		vertical-align: top;
	}
	.editor_input{
		background: transparent;
		display: inline-block;
		font-size: inherit;
		width: 50px;
   		text-align: right;
   		vertical-align: top;
	}
	.switch-container{
		margin-left: 16px;
	}
	.counter_editor_container{
		margin-right: 0px;
    	font-size: 16px;

	}
}
.counter_editor_menu{
	display: inline-block;
	margin-top: -4px;
	float: right;
	position: relative;
	margin-left: 23px;
	.hidden_select_list{
		width: 255px;
		height: 169.6px;
		top: 35px;
		left: initial;
		right: -13px;
		background: #FFF;
		z-index: 15;
		height: auto;
		padding-bottom: 15px;
		list-style: none;
		&:before{
			left: initial;
			right: 33px;
		}
		li:hover {
		    background-color: #f5f5f5;
		}
		.option_filter {
		    background: transparent;
		    cursor: pointer;
		    width: 100%;
		    text-align: left;
		    padding-left: 23px;
		    padding-top: 11px;
		    padding-bottom: 11px;
		    font-size: 1.4rem;
		}
		.delete_punkt{
			color: #ff4d4d;
		}
	}
	.deactive{
			visibility: hidden;
		    max-height: 0px;
	    	opacity: 0
	}
	.active{
		opacity: 1;
	}
}
.three_point{
	border-radius: 50%;
	background: #FFF;
	width: 28px;
	height: 28px;
	border: solid 1px #d7d9dc;
	position: relative;
	margin-left: 6px;
	.symbol{
		transition: all 0.4s ease;
	}
	svg{
		@include PosAbsCent;
	}
	&:hover{
		border-color: #4179f8;
		.symbol{
			fill: #4179f8;
		}
	}
}
.newCheckbox{
	margin-bottom: 15px;
	input{
		margin-left: 15px;
	}
}
@media screen and (max-width: 767px){

	.transport_profile{
		.edit_btn{
			width: 12px;
		}
		.profile_container{
			padding: 0px;
			padding-right: 7px;
		}
		._header{
			padding: 13px 0px;
			.tabs{
				margin-right: 20px;
			}
			.secondary_btn{
				&:after{
					@include clear;
				}
			}
			.vehicle_id{
				padding-top: 9px;
			}
		}
	}
	.editor_contrainer {
		padding: 7px 0px;
		.text_info{
			margin-right: 7px;
			margin-bottom: 0;
		}
		.input_container{
			min-width: 85px;
			display: flex;
			-webkit-justify-content: space-between;
			        justify-content: space-between;
		}
		.editor_input{
			width: 30px;
		}
		.edit_btn{
			margin-left: 5px;
		}
	}

}
