@import "mixin/mixin.scss";

.for_custom_checkbox{
	position: relative;
    text-align: center;
    width: 20px;
    display: inline-block;

}
.psevdo_label{
	    line-height: 17px;
    	vertical-align: top;
    	font-size: 13px;
    	a{
    		vertical-align: top;
    	}
}
.for_custom_checkbox{
		width: 16px;
		height: 16px;
		position: relative;
		margin-right: 11px;
	  	label {
			cursor: pointer;
			position: relative;
			border-radius: 4px;
			background-color: rgb(255, 255, 255);
			border: solid 1px rgb(154, 160, 167);
			z-index: 1;
			display: block;
			width: 100%;
			height: 100%;
			@include PosAbsCent;
		  //   &:after {
				
				// background-color: rgb(65, 121, 248);
				// opacity: 0;
				// z-index: 2;
  		// 		background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='12px' height='11px' viewBox='0 0 12 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 41.1 (35376) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EComplete%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Task' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Tasks' transform='translate(-1186.000000, -248.000000)' fill='%230E0E0E'%3E%3Cg id='task-1' transform='translate(20.000000, 159.000000)'%3E%3Cg id='controls' transform='translate(1124.000000, 81.000000)'%3E%3Cg id='complete' transform='translate(34.000000, 0.000000)'%3E%3Cg id='Complete' transform='translate(7.000000, 7.000000)'%3E%3Cpath d='M6.79578109,11.6087328 C6.4919271,12.0645599 5.88137671,12.1608722 5.44540743,11.8338502 L1.39973194,8.79917618 C0.957743544,8.46818487 0.868745879,7.84120132 1.20073717,7.4002129 C1.53172848,6.95822449 2.15871203,6.86922683 2.59970045,7.20021814 L5.75461766,9.56515608 L11.1674756,1.44536917 C11.4744675,0.985381242 12.0944513,0.86238447 12.5544392,1.16737647 C13.0144271,1.47436841 13.1384239,2.09435214 12.8314319,2.55434007 L6.79578109,11.6087328 Z' fill='white' id='Fill-1'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
				// background-position: center;
				// background-repeat: no-repeat;
				// background-size: 10px 8px;
		  //   }
		}
		input[type=checkbox] {
		    visibility: hidden;
		    &:checked + label{
		    	border-color: rgb(65, 121, 248);
		    	background-color: rgb(65, 121, 248);
				z-index: 2;
  				background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='12px' height='11px' viewBox='0 0 12 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 41.1 (35376) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EComplete%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Task' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Tasks' transform='translate(-1186.000000, -248.000000)' fill='%230E0E0E'%3E%3Cg id='task-1' transform='translate(20.000000, 159.000000)'%3E%3Cg id='controls' transform='translate(1124.000000, 81.000000)'%3E%3Cg id='complete' transform='translate(34.000000, 0.000000)'%3E%3Cg id='Complete' transform='translate(7.000000, 7.000000)'%3E%3Cpath d='M6.79578109,11.6087328 C6.4919271,12.0645599 5.88137671,12.1608722 5.44540743,11.8338502 L1.39973194,8.79917618 C0.957743544,8.46818487 0.868745879,7.84120132 1.20073717,7.4002129 C1.53172848,6.95822449 2.15871203,6.86922683 2.59970045,7.20021814 L5.75461766,9.56515608 L11.1674756,1.44536917 C11.4744675,0.985381242 12.0944513,0.86238447 12.5544392,1.16737647 C13.0144271,1.47436841 13.1384239,2.09435214 12.8314319,2.55434007 L6.79578109,11.6087328 Z' fill='white' id='Fill-1'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
				background-position: center;
				background-repeat: no-repeat;
				background-size: 10px 8px;
		    }
		}
}
.selected_item{
	display: inline-flex;
	-webkit-align-content: space-between;
	        align-content: space-between;
	    -webkit-align-items: center;
	            align-items: center;
    background-color: rgb(65, 121, 248);
	color: #FFF;
	padding: 4px 6px;
	-webkit-border-radius: 4px;
	        border-radius: 4px;
	margin-right: 12px;
	margin-bottom: 5px;
}
.realSensors_selectList {
	.selected_list{
		cursor: pointer;
	}
	.no_data {
		padding: 4px 6px;
		margin-top: 0;
	}
}
.remove{
    	background-color: rgb(65, 121, 248);
    	-webkit-border-radius: 50%;
		        border-radius: 50%;
		            width: 15px;
		            height: 15px;
		cursor: pointer;
		border: 1px solid rgb(65, 121, 248);
		margin-left: 15px;
	.remove_icon{
		fill: white;
	    width: 10px;
	}
	&:hover{
		opacity: 0.7;
	}
}
.selected_list{
	border: 1px solid rgb(65, 121, 248);
	padding: 10px;
	-webkit-border-radius: 12px;
	        border-radius: 12px;
	display: inline-block;
	margin-bottom: 15px;
	min-height: 47px;
	min-width: 280px;
}
.wrapper_of_list{
	width: 400px;
	max-height: 300px;
	position: relative;
	background: #FFF;
	-webkit-border-radius: 12px;
	        border-radius: 12px;
	overflow: hidden;
	transition: all 0.4s ease;
	.one_checkbox{
		padding: 7px 10px;
	}
}
.listForSelect{
	background: #FFF;
	padding-top: 15px;
	padding-bottom: 15px;
	
	max-height: 250px;
	overflow-y: auto;
}