@import "mixin/mixin.scss";

$width: 34px;
$height: 18px;
$off_background: rgb(215, 217, 220);
$on_background: $blue;
$circle_off_color: white;
$circle_on_color: white;
.switch-container {
	width: $width;
	height: $height;
 	display: inline-block;
}

input[type="checkbox"].switch  {
	position: absolute;
	opacity: 0;
}

input[type="checkbox"].switch + div {
	vertical-align: middle;
	border-radius: 999px;
	background-color: $off_background;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
	-webkit-transition-property: background-color, box-shadow;
	transition-property: background-color, box-shadow;
	cursor: pointer;
}



input[type="checkbox"].switch:checked + div {
	width: $width;
	background-position: 0 0;
	background-color: $on_background;
}

input[type="checkbox"].switch + div {
	width: $width;
	height: $height;
}


input[type="checkbox"].switch:checked + div {
	background-color: $on_background;
}

input[type="checkbox"].switch + div > div {
	float: left;
	width: $height - 2; height: $height - 2;
	border-radius: inherit;
	background: $circle_off_color;
	-webkit-transition-timing-function: cubic-bezier(1,0,0,1);
	transition-timing-function: cubic-bezier(1,0,0,1);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform, background-color;
	transition-property: transform, background-color;
	pointer-events: none;
	margin-top: 1px;
	margin-left: 1px;
}

input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(20px, 0, 0);
	transform: translate3d(20px, 0, 0);
	background-color: $circle_on_color;
}

input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d($height - 2, 0, 0);
	transform: translate3d($height - 2, 0, 0);
}


// .switch_tooltip{
// 	visibility: hidden;
// 	opacity: 0;
// 	font-size: 14px;
// 	letter-spacing: 0.2px;
// 	text-align: center;
// 	color: rgb(255, 255, 255);
// 	opacity: 1;
// 	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
// 	background-color: rgb(66, 79, 91);
// 	border-radius: 1px;
// 	height: 36px;
// 	padding: 8px 24px;
// 	position: absolute;
// 	left: -200px;
// 	right: -200px;
// 	top: -41px;
// 	margin: auto;
// 	display: inline-block;
// 	white-space: pre;

// 	z-index: 2;
// 	transition: all 0.4s ease;
// 	 &:before{
//  		position: absolute;
//  		content: '';
//  	    border-left: 7px solid transparent;
// 	    border-right: 7px solid transparent;
// 	    border-top: 6px solid rgb(66, 79, 91);
// 	    display: block;
// 	    width: 0px;
// 	    height: 0px;
// 	    right: 0px;
// 	    margin: auto;
// 	    left: 0px;
// 	    z-index: 1;
// 	    bottom: -6px;
// 	 }
// }