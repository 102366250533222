$headerHeight: 70px;
$border_block: #dfe6ec;
$grey_line: #eaeaea;
$grey_text: #9aa0a7;
$blue: #708090;
$orange: rgba(242, 101, 34, 1);
$input_focus: rgba(126, 114, 108, 0.7);
$grey_firm_style: #666666;
$black: #231f20;

$headerBackground: mix($grey_firm_style, $black);
$border: solid 1px $blue;
$border-radius: 4px;
/*rgba(119, 115, 115, 0.45);*/
$color_border_table: #d7d9dc;
$placeholder: rgba(119, 115, 115, 0.8);
$light_grey_back: #d3d3d31a;

@mixin inputBorderRadius {
	-webkit-border-radius: calc(#{$border-radius } * 2);
	border-radius: calc(#{$border-radius } * 2);
}

@mixin formBorderRadius {
	-webkit-border-radius: calc(#{$border-radius } * 2 + 2px);
	border-radius: calc(#{$border-radius } * 2 + 2px);
}

@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}

	;

	&:-moz-placeholder {
		@content
	}

	;

	&::-moz-placeholder {
		@content
	}

	;

	&:-ms-input-placeholder {
		@content
	}

	;
}

@mixin btn_text_style {
	font-size: 13px;
	font-family: $Roboto;
	letter-spacing: 0.2px;
	text-decoration: none;
	line-height: 13px;

	&:disabled {
		opacity: 0.3;
		cursor: not-allowed;
		pointer-events: none;

		&:not:hover {}
	}
}

@mixin collapsedText {
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	// width: 100%;
	white-space: nowrap;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


// .foo {
//   @include placeholder {
//     color: green;
//   }
// }

// @include placeholder {
//   color: red;
// }

.scroll {
	// @extend .scroll
	// overflow-y:scroll;
}

.scroll::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 11px;
	margin-top: 4px;
	margin-bottom: 10px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #9aa0a7;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.scroll::-webkit-scrollbar-thumb:window-inactive {
	background: #9aa0a7;
}

.clear {
	content: " ";
	display: block;
	clear: both;
	float: none;
}

.one_block {
	border-bottom: 1px solid #708090;
	margin-bottom: 20px;
	padding-bottom: 20px;
}

@mixin clear {
	content: " ";
	display: block;
	clear: both;
	float: none;
}

@mixin PosAbsVertical {
	position: absolute;
	margin: auto;
	top: 0px;
	bottom: 0px;
	z-index: 1;
}

@mixin PosAbsCent {
	position: absolute;
	margin: auto;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 1;
}

@mixin PosFixCent {
	position: fixed;
	margin: auto;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 1;
}

@mixin transitionall {
	transition: all 0.4s ease;
}

@mixin box_shadow_select_list {
	box-shadow: 0 2px 4px 0 rgba(41, 64, 86, 0.17);
}

@mixin collapsedText {
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	// width: 100%;
	white-space: nowrap;
}