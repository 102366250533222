@import 'mixin/mixin.scss';

$headerHeightOnMobile: 50px;
$headerHeightOnDesktop: 70px;

.table_footer {
	height: 46px;
	width: 100%;
	padding-right: 75px;
	// padding-top: 20px;
	display: flex;
	// -webkit-justify-content: space-between;
	//         justify-content: space-between;
	-webkit-align-items: center;
	align-items: center;
	border: 1px solid $grey_firm_style;
	border-top: none;
	background: $grey_text;

	.count_of_lines_cell,
	.lines_info,
	.pagination_block {
		display: inline-block;
		padding-left: 20px;
		padding-right: 20px;
		font-weight: 700;
		margin-right: 47px;
	}

	.count_of_lines_cell {
		position: relative;
		height: 20px;
		overflow: visible;
		padding-left: 26px;
		padding-right: 0;
		// width: 500px;
	}

	.count_of_lines_list {
		// width: 100%;
		border-top: 1px solid $grey_line;
		position: absolute;
		right: 0;
		text-align: center;
		padding: 0px 16px 10px 10px;
		z-index: 10;
		width: 50px;
		background: $grey_line;
		@include box_shadow_select_list;

	}

	.selected_value {
		position: relative;
		padding-right: 16px;
		padding-left: 10px;
		border: 1px solid $grey_line;
		background: $grey_line;
		height: 20px;
		width: 50px;
		display: inline-block;
		margin-left: 7px;

		.triangle {
			right: 4px;
			height: 6px;
			@include PosAbsVertical;
		}
	}

	.lines_info {
		.index_info {
			padding: 0 15px;
		}

		.all_available_lines {
			padding: 0 15px;
		}
	}


	.pagination_block {
		// .prevPageIcon{
		// 	font-size: 75px;
		// 	height: 75px;
		// }
		line-height: 0;

		.pagintation_button {
			margin-right: 15px;
		}

		button[name='go_to_prev'] {
			margin-right: 50px;

			&:disabled {
				opacity: 0.4;
			}
		}

		button[name='go_to_next'] {
			&:disabled {
				opacity: 0.4;
			}
		}
	}

	.paginationIcons {
		height: 17px;

		&:hover {

			polygon,
			rect {
				fill: $orange;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.table_footer{
		padding-right: 10px;

		.pagination_block{
			margin-right: auto;
		}
		.lines_info{
			margin-right: auto;
		}
		.count_of_lines_cell{
			margin-right: auto;
		}
		.count_of_lines_list{
			@include PosFixCent;
			width: 100vw;
			top: $headerHeightOnMobile;
			height: calc(100vh - #{$headerHeightOnMobile});
			padding-top: 75px;
			.count_of_lines_mobile{
				flex-direction: column;
				justify-content: center;
				display: flex;
				-webkit-align-items: center;
				        align-items: center;

			}
			.btn_close {
				position: absolute;
				right: 15px;
				top: 15px;
				svg{
					width: 20px;
					height: 20px;
				}
			}
			.name_of_popUp_lines_mobile{
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 45px;
			}
			.item_of_count_line{
				.count_line{
					font-size: 15px;
					margin-bottom: 10px;
					padding: 15px;
					border: 1px solid $border_block;
					-webkit-border-radius: 2px;
					        border-radius: 2px;
					background: rgba($grey_firm_style, 0.7);
					color: white;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.table_footer{
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	    -webkit-justify-content: center;
	            justify-content: center;
	    height: auto;
	    padding-top: 10px;
	    padding-bottom: 10px;
	    .count_of_lines_cell, .lines_info{
	    	margin: auto;
	    	margin-bottom: 10px;
	    }
	    .pagination_block{
	    	margin: auto;
	    }
	}
}