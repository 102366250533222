.custom-select {
    width: 120px;
    position: relative;
    user-select: none;
}

.select-header {
    padding: 10px;
    cursor: pointer;
/*rgba(211, 211, 211, 0.1019607843)*/
    background-color: rgba(211, 211, 211, 0.1019607843);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
}

.select-header .arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid black;
    display: block;
    width: 4px;
    height: 4px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    z-index: 1;
    right: 0;
}

.select-header .arrow.open {
    transform: rotate(180deg);
}

.select-options {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #c9cbce;
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: none;
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 10;
    max-height: 135px;
    overflow-y: auto;
}

.select-option {
    padding: 10px;
    cursor: pointer;
}

.select-option:hover {
    background-color: #f0f0f0;
}
