/*pop_up.scss*/
@import "mixin/mixin.scss";
@import "fonts.scss";
.pop_mask{
	position: fixed;
	z-index: 100400;
	opacity: 0.9;
  	background-color: #424f5b;
  	left: 0px;
  	right: 0px;
  	top:0px;
  	bottom: 0px;
}
.pop_up{
	width: 400px;
	@include PosFixCent;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.33);
	max-width: 100%;
	z-index: 100500;
	@extend .scroll;
	overflow: auto;
}
.pop_header {
	position: relative;
	height: 4.9rem;
	line-height: 4.9rem;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 2px;
	span{
		font-size: 13px;
		  font-weight: 500;
		  letter-spacing: 0.3px;
		  color: inherit;
		  text-transform: uppercase;
		  font-family: $Rmedium;
	}

	.close{
		right: 19px;
		fill: inherit;
		left: auto;

		svg{
			fill: inherit
		}
		g {
			fill: inherit
		}
		.symbol{
			fill: inherit
		}
		@include PosAbsVertical;
		&:hover{
			fill: #4179f8;
		}
	}

}
.pop_up_content{
	padding: 24px 20px 18px;
	padding-left: 20px;
	padding-right: 20px;
}
.pop_up_footer{
	border-top: 1px solid #eaeaea;
	padding-top: 18px;
	text-align: right;
	.secondary_btn{
		margin-right: 20px;
	}
	&:after{
		@include clear;
	}
}
.remove_container{
	padding: 36px 20px 22px;
	.primari_btn{
		float: right;
		min-width: 117px;
	}
	.secondary_btn{
		float: right;
		margin-right: 20px;
	}

}
.confirm_text{
	text-align: center;
	font-size: 16px;
	color: rgb(14, 14, 14);
	margin-bottom: 36px;
}
@media screen and (max-width: 767px){
	.pop_header{
		height: 4.6rem;
		line-height: 4.6rem;
	}
	.remove_container{
		    padding: 24px 20px 22px;
	}
	.confirm_text{
		margin-bottom: 30px;
		text-align: left;
		font-size: 14px;
	}
}