/*fonts.scss*/
/** Generated by FG **/
 @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;1,400&display=swap');


// @font-face{
// 	font-family: 'Roboto-Regular';
// 	src: url('../fonts/Roboto-Regular.eot');
// 	src: local('☺'), url('../fonts/Roboto-Regular.woff') format('woff'), url('../fonts/Roboto-Regular.ttf') format('truetype'), url('../fonts/Roboto-Regular.svg') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// 	weight: normal;
// 	font-style: normal;
// }
// $Roboto: Roboto-Regular, sans-serif;
$Roboto: ("Roboto", Arial, sans-serif);

// @font-face{
// 	font-family: 'Roboto-Bold';
// 	src: url('../fonts/Roboto-Bold.eot');
// 	src: local('☺'), url('../fonts/Roboto-Bold.woff') format('woff'), url('../fonts/Roboto-Bold.ttf') format('truetype'), url('../fonts/Roboto-Bold.svg') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }


// $Rbold: Roboto-Bold, sans-serif;
$Rbold: ("Roboto", Arial, sans-serif);

// @font-face{
// 	font-family: 'Roboto-Medium';
// 	src: url('../fonts/Roboto-Medium.eot');
// 	src: local('☺'), url('../fonts/Roboto-Medium.woff') format('woff'), url('../fonts/Roboto-Medium.ttf') format('truetype'), url('../fonts/Roboto-Medium.svg') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }

// $Rmedium: Roboto-Medium, sans-serif;
$Rmedium: ("Roboto", Arial, sans-serif);

// @font-face{
// 	font-family: 'Roboto-Light';
// 	src: url('../fonts/Roboto-Light.eot');
// 	src: local('☺'), url('../fonts/Roboto-Light.woff') format('woff'), url('../fonts/Roboto-Light.ttf') format('truetype'), url('../fonts/Roboto-Light.svg') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }


// $RLight: Roboto-Light, sans-serif;
$RLight: ("Roboto", Arial, sans-serif);

