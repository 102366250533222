//notif_menu.scss
.notif_menu{
	  	height: 130px;
  		background-color: rgb(245, 245, 245);
  		padding-top: 34px;
  		color: rgb(14, 14, 14);
  		h1{
  			font-size: 16px;
  			color: inherit;
  			font-weight: 500;
  			margin-bottom: 6px;
  		}
  		p{
  			font-size: 14px;
  			color: inherit;
			margin-bottom: 26px;
  		}
  		li{
  			display: inline-block;
  			&:first-child{
  				a{
  					margin-left: 0px;
  				}
  			}
  		}
  		a{
  			padding-bottom: 9px;
  			opacity: 0.5;
  			&:hover{
  				opacity: 1;
  			}
  		}
      .dop_menu_link{
        margin-left: 38px;
      }
}
.notif_menu_container{
	width: 600px;
	max-width: 100%;
	margin: auto;
}
.oneNotif_container{
  display: flex;
}
@media screen and (max-width: 767px){
    .notif_menu_container{
      padding-left: 10px;
      padding-right: 10px;
      ul{
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
              -webkit-align-content: center;
                      align-content: center;
      }
    }
    .notif_menu{
      padding-top: 21px;
      a{
        margin-left: 10px;
        
      }
    }
}@media screen and (max-width: 700px){
  
    .notif_menu_container {
        .link_block{
            overflow: auto;
            ul{
                 min-width: 521px;
            }
        }   
    }    
   .notif_menu{
        height: 116px;
        p{
            font-size: 13px;
            margin-bottom: 15px;
        }
        .dop_menu_link {
            margin-left: 15px;
            padding-bottom: 5px;
        }
   }
}