@import 'mixin/mixin.scss';
.error_boundary{
	width: 1088px;
	max-width: 100%;
	height: 500px;
	max-width: 100%;
	@include PosAbsCent;
	z-index: 100500100;
	background: #0075C4;
	color: white;
	display: flex;
	padding-right: 15px;
	.error_back{
		height: 100%;
		width: auto;
		max-width: 100%;
	}
	.primari_btn{
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.error_info_container{
		padding: 50px 0;
		width: 640px;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		details{
			overflow: hidden;
			padding-bottom: 15px;
			margin-top: 10px;
			margin-bottom: auto;
		}
		h3{
			padding: 10px 0;
		}

	}
}
@media screen and (max-width: 767px) {
	.error_boundary{
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
		.error_back{
			height: 26vh;

		}
	}
}