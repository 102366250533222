@import "mixin/mixin.scss";

$color_border_table: #d7d9dc;

@media screen and (min-width: 1500px) {
	.container {
		max-width: 1920px;
		margin: auto;
	}

	#global_search {
		margin-right: 234px;
	}

	.content {
		padding: 0px 25px 25px;
	}
}

@media screen and (max-width: 1800px) {
	.administratorList {
		.oneLine:first-child {
			// height: 49px;

			.premissions {
				.cell {
					font-size: 13px;
					font-weight: 600;
				}
			}
		}

		.oneLine {

			.cell_byWhom,
			.cell_login {
				width: 9%;

			}

			.cell {
				padding-left: 10px;
				padding-right: 10px;
			}
		}

		.premissions {
			width: calc(82% - 80px);
		}
	}
}

@media screen and (max-width: 1500px) {
	.select_label {
		width: 52%;
	}

	.form_block {
		flex-wrap: wrap;
		margin-top: 0px;

		form {
			margin-bottom: 60px;
		}
	}
}

@media screen and (max-width: 1270px) {
	.form_block {
		max-width: 900px;
		justify-content: space-between;

		.changePass,
		.form_blocking {
			margin-bottom: 15px;
		}

		.form_edit_vehicle_owner {
			min-width: auto;
		}
	}

	.administratorList {
		.oneLine:first-child {
			height: 67px;

			.premissions {
				.cell {
					font-size: 11px;
					font-weight: 600;
				}
			}
		}

		.oneLine {

			.cell_byWhom,
			.cell_login {
				width: 9%;

			}

			.cell {
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		.premissions {
			width: calc(82% - 80px);
		}
	}

	.form_block .changePass {
		.btn_black {
			margin-top: 0px;
		}
	}
}

@media screen and (max-width: 1180px) {
	body {
		overflow-x: hidden;
	}

	header {
		z-index: 100499;
	}

	// $menu_height:
	.main_menu {
		display: block;
		position: absolute;
		visibility: hidden;
		right: -100%;
		top: 45px;
		margin-top: 0;
		background: #1d2329;
		padding-right: 35px;
		padding-left: 35px;
		padding-bottom: 15px;
		-webkit-transition: all .4s ease;
		transition: all .4s ease;
		height: auto;

		li {
			display: block;
			margin-right: 0px;
			padding-top: 10px;
			padding-bottom: 10px;
		}

		a {
			font-size: 1.8rem;

		}

		.left_part {
			margin-left: 0px;
			display: block;
		}

		.subMenu_item {
			height: auto;
			padding: 0;

			.sub_menu_no_item {
				display: none;
			}
		}

		.right_part {
			display: block;
		}

		.login_link {
			padding-top: 10px;
			padding-bottom: 0;
		}

		.hidden_content {
			position: static;
			height: auto;
			visibility: visible;
			opacity: 1;
			padding: 0;
		}
	}

	.menu_triger {
		position: absolute;
		right: 13px;
		top: 13px;
		width: 30px;
		-webkit-transition: top .4s ease;
		transition: top .4s ease;
		z-index: 100500;
		cursor: pointer
	}

	.menu_triger div {
		width: 100%;
		height: 3px;
		background: #03a6d7;
		margin-bottom: 6px;
		-webkit-transition: all .4s ease-out, opacity .2s;
		transition: all .4s ease-out, opacity .2s
	}

	.menu_active {
		cursor: pointer;
		top: 23px
	}

	.menu_active div {
		opacity: 0.8;
	}

	.menu_active .first {
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		top: 0
	}

	.menu_active .third {
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		position: absolute;
		top: 0
	}

	.menu_active .second {
		opacity: 0;
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg)
	}

	.visible_on_mobile {
		display: block
	}

	.show_menu {
		right: 0;
		z-index: 100500;
		visibility: visible;
		.hidden_content{
			p, .login_link, .login_link{
				padding-top: 7px;
				padding-bottom: 7px;
				margin-bottom: 7px;
			}
		}
	}

	.subMenu_item {}
}

@media screen and (max-width: 1000px) {
	// .administratorList {
	// 	border: none;

	// 	.head_line:first-child {
	// 		border-left: 1px solid $color_border_table;

	// 		&:after {
	// 			width: 3px;
	// 			position: absolute;
	// 			bottom: -42px;
	// 			left: -2px;
	// 			height: 41px;
	// 			background: white;
	// 		}

	// 		.cell_delete,
	// 		.cell_edit {
	// 			display: none;
	// 		}

	// 	}

	// 	.oneLine {
	// 		flex-direction: column;
	// 		width: 100px;
	// 		float: left;
	// 		height: auto;
	// 		border-bottom: none;
	// 		border-top: 1px solid $color_border_table;

	// 		.premissions {
	// 			width: 100%;
	// 			flex-direction: column;
	// 		}

	// 		.cell_login,
	// 		.cell_byWhom {
	// 			width: 100%;

	// 		}

	// 		.cell {
	// 			height: 41px;
	// 			border-bottom: 1px solid $color_border_table;

	// 			p {
	// 				text-align: center;
	// 			}
	// 		}

	// 		.cell_edit,
	// 		.cell_delete {
	// 			border: none;
	// 			// border: 1px solid $color_border_table;
	// 			width: 50px;

	// 			&:after {
	// 				display: none;
	// 			}

	// 			svg {
	// 				opacity: 1;
	// 			}

	// 		}

	// 		.cell_delete {
	// 			align-self: flex-end;
	// 			margin-top: -41px;
	// 		}
	// 	}

	// 	.oneLine:first-child {
	// 		height: auto;
	// 	}

	// 	position: relative;
	// 	z-index:1;

	// 	.head_line {
	// 		position: absolute;
	// 		z-index: 1;
	// 	}

	// 	.table_content {
	// 		position: relative;
	// 		top: 0px;
	// 		z-index: 1;
	// 		left: 100px;
	// 		overflow-y: hidden;
	// 		overflow-x: hidden;
	// 		max-width: calc(100% - 100px);

	// 		.oneLine {
	// 			width: 140px;

	// 			.cell_login {
	// 				text-align: center;
	// 			}

	// 			.target_group {
	// 				left: 0px;
	// 				right: 0px;
	// 				text-align: center;
	// 			}
	// 		}
	// 	}
	// }
	.content {
		padding-bottom: 0px;
	}

	.triggerUp,
	.triggerDown {
		position: absolute;
		z-index: 15;
		right: -12px;
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		background: black;
		color: #FFF;

		&:hover,
		&:active {
			opacity: 0.7;
		}
	}

	.transportlist,
	.devicelist,
	.administratorList,
	.userlist,
	.clientslist {
		position: relative;
	}

	.triggerUp {
		top: 41px;
	}

	.triggerDown {
		top: 93px;
	}

	.show_form {
		max-width: 83%;
	}

	.admin_description {
		right: 0px;
		top: 117%;

		&:before {
			border: 8px solid transparent;
			border-bottom: 8px solid white;
			top: -16px;
			left: 50%;
			margin: auto;

		}
	}

	.oneLine {

		.form_to_passwordChange,
		.form_to_belongsToChange,
		.FormChangeDesc {
			top: -4px;

			.btn_black {
				margin: auto
			}
		}
	}

	.devicelist {
		.oneLine {
			.cell_delete {
				svg {
					opacity: 1;
				}
			}
		}
	}

	.clientlist,
	.userlist {
		.oneLine {

			.cell_delete,
			.cell_edit {
				svg {
					opacity: 1;
				}
			}
		}

	}

	.clientlist,
	.userlist,
	.devicelist,
	.transportlist {
		.visible_on_mobile {
			display: none;
		}
	}
}

@media screen and (max-width: 768px) {
	.main_menu{
		padding-right: 7px;
		padding-left: 15px;
		width: 286px;
		padding-top: 12px;
		li {
			padding-top: 7px;
			padding-bottom: 7px;
			margin-bottom: 4px;
			a {
				font-size: 15px;
			}
		}
		.login_link, .exit_link{
			padding-left: 30px;
			position: relative;
			.avo4ka, .exit{
				left: 0;
			}
		}
		.hidden_content{
			width: 100%
		}
	}
	.SensorsInfoOnDevice .listOfVirtualSensor{
		.btn_black{
			padding-right: 25px;
		}
	}
	.device_settings, .transport_profile{
		.nameForm, .profile_containe, ._header{
			padding-left: 0;
		}
	}
	.administratorList,
	.clientlist,
	.devicelist,
	.transportlist,
	.trailers_list,
	.userlist,
	.server_list,
	.logTable, .device_list {
		.oneLine {

			.cell_delete,
			.cell_edit,
			.cell_copy {
				width: 35px;
				min-width: 30px;
				padding-left: 2px;
				padding-right: 2px;
				border: none;
				position: relative;
				background: #FFF;

				&:after {
					content: '';
					display: block;
					position: absolute;
					bottom: 0px;
					left: 0px;
					height: 2px;
					width: 100%;
					background: #FFF;
				}

				svg {
					opacity: 1 !important;
					transition: all 0.3s ease;
				}

				.copy_btn {
					opacity: 1;
					transition: all 0.3s ease;
				}
			}
		}
	}
	.cell_login, .cell_name {
		position: relative;
		.goToClient, .showQR{
			@include PosAbsVertical;
			right: 3px;
		}
		.showQR{
			right: 36px;
		}
	}
	.client_page {
		.transportlist{

		}
	}
	.transportlist, .trailers_list, .device_list, .clientlist, .userlist, .devicelist{
		-ms-overflow-y: scroll;
		    overflow-y: scroll;

		.table_content, .head_line, .oneLine{
			min-width: 1200px;
		}
	}
	.device_list{
		// max-height: 60vh;

		.table_content{
			height: auto;
		}
		.cell_date{
			min-width: 220px;
		}
	}
	.server_list, .logTable{
		-ms-overflow-y: scroll;
		    overflow-y: scroll;
		.oneLine{
			min-width: 1000px;
			.cell_add{
				min-width: 32px;
			}
		}
	}
	.logs {
		.one_filter{
			margin-bottom: 12px;
			max-width: 100%;
			p{
				padding-bottom: 0;
				margin-bottom: 7px;
			}
		}
	}
	.page_name_client{
		width: 100%;
		margin-bottom: 12px;
		padding-bottom: 0;
	}
	.right_of_name{
		float: none;
		margin-top: 0;
		margin-bottom: 12px;
	}
	.search_wrapper{
		margin-bottom: 12px;
		max-width: 100%;
	}
	.userCapability_form{
		margin-bottom: 20px;
	}
	.space_between {
		display: block;

		.search_wrapper,
		.filter_transport,
		.add_info {
			display: block;
			float: none;
			text-align: left;
			margin-left: 0;
			margin-bottom: 7px;
		}
	}

	.Mshow {
		max-height: 10000px;
	}

	.nodata {
		margin-top: 55px;
		padding-left: 15px;
	}

	.tubs_container {
		.tub_button {
			padding: 8px 7px;
		}
	}

	.add_info .react-phone-number-input {
		max-width: 100%;
	}

	.add_info_form {
		top: 70px;
		width: 360px;
		max-width: 85%;

		.select_label {
			width: 90%;
		}

		.belongsTo,
		.form_text_area {
			float: none;
		}

		.form_text_area {
			margin-bottom: 15px;
		}

		.input,
		.form_text_area {
			display: block;
			width: 100%;
		}

		.btn_black {
			margin-left: 0px;
		}
	}

	.time_input_container {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;

		label {
			display: block;
		}
	}

	// .clientlist {
	// 	margin-top: 0px;
	// 	-ms-overflow-y: scroll;
	// 	    overflow-y: scroll;
	// 		width: 100%;
	// 	.oneLine {
	// 		width: 380px;
	// 	}

	// 	.cell_name {
	// 		width: 60%;
	// 	}

	// 	.cell_admin {
	// 		width: 30%;
	// 	}

	// 	.cell_edit,
	// 	.cell_delete {
	// 		width: 20px;
	// 	}

	// 	.cell_blocked,
	// 	.cell_expire,
	// 	.cell_desc {
	// 		display: none !important;
	// 	}

	// 	.showQR {
	// 		display: none;
	// 	}
	// }

	.search_input {
		margin-bottom: 15px;
	}

	.devicelist {
		.oneLine {
			.cell_date {
				height: 61px;
				line-height: 1.8rem;
			}
		}
	}

	.transportlist {
		position: relative;

		.head_line {
			.cell {
				border-left: 1px solid #d7d9dc;
			}
		}
	}

	.sensor_field {
		max-width: 100%;
	}

	.form_block form {
		margin-bottom: 29px;

		.form_message {
			margin-bottom: 2px;
			margin-top: 0px;
		}
		p{
			margin-bottom: 0;
		}
	}

	.form_message {
		font-size: 1.2rem;
	}

	.existings_sensorType,
	.connect_user {
		.form_message {
			margin-bottom: 2px;
			margin-top: 0px;
			font-size: 0px;
		}
	}
	.existings_sensorType, .real_sensors{
		.cell_info{
			min-width: 200px;
		}
		.device_list{
			-ms-overflow-y: auto;
			    overflow-y: auto;
			min-width: 900px;
		}
	}
	.device_page {
		padding-bottom: 30px;
		.current_info {
			max-width: 100%;
			margin-bottom: 15px;
			float: none;
			text-align: left;
			border: none;

			.date_for {
				text-align: left;
				padding-left: 0;

				div {
					text-align: left;
				}
			}
		}
		.right_part_on_device_page{
			float: none;
		}
		.vehicle_mapping_list{
			height: auto;
		}
	}

	.current_info {

		.info_block{
			width: 100%;
			height: auto;
		}
		.map {
			max-width: 100%;
			max-height: 300px;
			float: none;
		}

		.btn_black[name='date_request'] {
			width: 200px;
			margin-left: 0;
		}
	}

	.command_to_device {
		.input {
			margin-bottom: 10px;
			margin-right: 10px;
		}

		.btn_black {
			margin-left: 0;
		}
	}

	.command_toDevice_in_mount {
		display: block;

		.addsensor {
			margin-bottom: 15px;
	        margin-right: 17px;
		}
	}

	.fast_command {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	    .psevdo_time_input{
	    	margin-right: 17px;
	    }
	    .date_for > div {
	    	.btn_black{
	    		width: auto;
	    	}
	    }
	}
}

@media screen and (max-width: 700px) {
	.container_calendar .calendar-date-panel {
		left: 0px;
		top: 172px;
	}

	.mount_form .close {
		top: 148px;
		left: 320px;
	}

	.mount_form .close2 {
		top: 199px;
	}

	.container_calendar2 .calendar-date-panel {
		top: 220px;
	}

	.autorization_form {
		max-width: 100%;

		.form_input,
		.rememberMe {
			max-width: 80%;
		}

		.btn {
			font-size: 23px;
		}

	}
}

@media screen and (max-width: 650px) {
	.logo{
		margin-left: 7px;
	}
	.menu_triger{
		right: 7px;
	}
	.content{
		padding-left: 7px;
		padding-right: 7px;
	}
	.re_request_data {
		.date_for > div {
			-webkit-flex-wrap: wrap;
					    -ms-flex-wrap: wrap;
					        flex-wrap: wrap;
		    -webkit-justify-content: center;
		            justify-content: center;
		    .time_input_container{
		    	margin-right: 17px;
		    }
		}
	}
	// .re_request_data {
	// 	.time_input_container {
	// 		margin-bottom: 15px;
	// 	}
	// }
	.current_info {
		.date_for>div {
			text-align: left;
		}
	}

	.update_sensor {
		max-width: 100vw;
		max-height: 100vh;
		-ms-overflow-y: auto;
		overflow-y: auto;

		.create {
			width: 100%;
		}
	}

	.sensor_field_container {
		.units {
			margin-bottom: 10px;
		}

		.title {
			float: none;
			margin-bottom: 10px;
		}
	}

	.calendar_container {
		max-width: 100%;
	}
}

@media screen and (max-width: 610px) {
	.right_of_name {
		float: none;
		margin-top: 0;
	}

	.page_name_client {
		padding-right: 0;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: 17px;
		padding-top: 10px;
		span{
			padding-left: 7px;
			text-transform: uppercase;
		}
	}
	.device_page{
		.go_back{
			display: none;
		}
	}
	.form_block {
		.form_message_for_client {
			width: 100%;
		}
	}

	.transportlist,
	.trailers_list,
	.devicelist,
	.userlist {
		-ms-overflow-x: auto;
		overflow-x: auto;
		min-height: auto;

		/* .head_line,
		.table_content {
			min-width: 768px;
		} */

		.oneLine {

			.cell_date,
			.cell_comment {
				display: none;
			}
		}
	}

	.transports {
		.transport_form {
			top: 40px;
		}

		.page_name {
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			font-size: 16px;
			margin-bottom: 10px;
			padding-bottom: 0;
			padding-top: 0;

			.count_connected_vehicles {
				font-size: 14px;
			}
		}


		.space_between {
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
		}

		.search_input {
			width: 100%;
		}
	}

	.container {
		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.sensor_field {
		max-width: 90%;

		.title {
			float: none;
			display: block;
		}
	}

	.transport_form .units {
		float: none;
		display: block;
	}

	.sensor_types {
		.input_block {
			width: 100%;
			max-width: 400px;
			padding-top: 0px;
		}

		.textarea_container {
			padding-left: 0px;
		}
	}

	.sensor_mapping {
		max-width: 100%;
	}

	.remove_equipment {
		margin-left: auto;
	}

}

@media screen and (max-width: 560px) {
	.add_info {
		float: none;
		// margin-top: 15px;
		margin-bottom: 15px;
	}
	.device_page {
		.add_info{
			margin-bottom: 0;
		}
		.page_name_client{
			padding-bottom: 0;
			margin-bottom: 20px;
		}
		.device_info{
			.name_client{
				padding-bottom: 0;
				span, a{
					font-size: 14px;
				}
			}
		}
	}
	.administratorList,
	.devicelist,
	.transportlist {

		//.clientlist, .userlist,
		// margin-top: 60px;
		.oneLine {
			max-width: 100%;
		}
	}

	.search_input {
		max-width: 100%;
		display: block;
	}

	.edit_device_on_transport_container {
		.mount_form {
			padding-bottom: 0px;
		}

		.nameParagraph {
			font-size: 1.4rem;

			span {
				font-weight: 600;
			}
		}
	}

	.message {
		.message_text {
			font-size: 1.4rem;
		}

		.close {
			right: 0px;
			top: 0px;
		}
	}

}

@media screen and (max-width: 450px) {

	.page_name,
	.page_name_client {
		font-size: 17px;
		-webkit-align-items: center;
		        align-items: center;
		    padding-bottom: 0;
		    .goToClient{
		    	margin-left: auto;
		    	margin-right: 7px;
		    }
		    .showQR{
		    	margin-right: 0;
		    	margin-left: auto;
		    }
	}
	.right_of_name{
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
		.one_switch{
			width: 100%;
		}
	}
	.administratorList .table_content .oneLine {
		width: calc(100% - 30px);
	}

	.edit_device_on_transport {
		.close {}
	}

	.form_block {
		flex-direction: column;
	}

	.edit_device_on_transport {
		.nameParagraph {
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;

			.primari_btn {
				margin-left: 0 !important;
			}
		}
	}
}

@media screen and (max-width: 400px) {
	.fast_command .re_request_data{
		width: 100%;
		.date_for>div{
			-webkit-justify-content: flex-start;
			        justify-content: flex-start;
		}
		.time_input_container{
			margin-bottom: 7px;
			.time_input_label{
				margin-bottom: 7px;

			}
		}
	}
	html {
		font-size: 10px;
	}
	.nameForm,
	.edit_device_on_transport_container .nameForm {
		font-size: 1.6rem;
	}

	.edit_device_on_transport_container .nameForm {
		font-size: 1.6rem;

	}

	.form_blocking {
		button[name=blocked] {
			margin-bottom: 15px;
		}
	}

	.connect_user {
		.btn_black {
			display: block;
			margin-top: 15px;
		}
	}

	.hidebtn {
		left: auto;
		right: 0px;
		font-size: 1.5rem;
		top: 6px;
	}

	.existing_sensors {
		padding-top: 5px;
	}

	.add_info_form .btn_black,
	.form_to_passwordChange .btn_black,
	.form_to_belongsToChange .btn_black,
	.FormChangeDesc .btn_black {
		padding: 9px 46px;
	}
}

@media screen and (max-width: 376px) {
	.page_name_client{
		.showQR{
			margin-right: auto;
			margin-left: auto;
		}
		.goToClient{
			margin-right: auto;
			margin-left: auto;
		}
		span{
			text-transform: uppercase;
		}
	}

	.form_change_vehicle_name {
		max-width: 100%;
	}

	.triggerUp,
	.triggerDown {
		top: -30px;

	}

	.triggerUp {
		right: 45px;
	}

	.triggerDown {
		right: 10px;
	}

	.btn_black {
		font-size: 13px;
	}

	p {
		margin-bottom: 10px;
	}

	.calendar-date-panel,
	.container_calendar .calendar-date-panel {
		width: 300px;
	}

	.close {
		right: -17px;
	}

	.device_form .calendar_container {
		max-width: 100%;

		.calendar-date-panel {
			width: 100%;
		}

		.calendar-body {
			margin-top: 32px;
		}

	}

	.device_form input {
		width: 100%;
	}
	.mount_form .close,
	.mount_form .close {
		left: auto;
		right: 0px;
	}

}

@media screen and (max-width: 320px) {
	.on_transportpage form textarea {
		max-width: 296px;
	}

	.form_block form {
		max-width: 100%;
		min-width: 287px;
	}

	.calendar-date-panel,
	.container_calendar .calendar-date-panel {
		width: 100%;
	}

	.name_client {
		float: none;
		display: block;
		margin-top: 0px;
		margin-bottom: 15px;
	}

	.page_name_client {
		margin-bottom: 0px;
	}
}