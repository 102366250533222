@import 'mixin/mixin.scss';
.one_checkbox_def {
		@include clear;
		// border-bottom: 1px solid rgb(223, 230, 236);
		padding-bottom: 10px;
		padding-top: 10px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		min-height: 35px;

		.for_custom_checkbox,
		.psevdo_label {
			font-size: 14px;
			// vertical-align: middle;
		}

		.input_range_container {
			margin-right: 10px;
			min-width: 170px;

			.react-numeric-input {
				b {
					&:hover {
						i {
							border-bottom-color: white !important;

						}
					}
				}

				b:last-child {
					&:hover {
						i {
							border-top-color: white !important;
						}
					}

				}
			}
		}
	}