$backColor: #141e30;
$backGradient: linear-gradient(#141e30, #243b55);

#form_container {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	background-color: $backColor;
	background: $backGradient;
	width: 100vw;
	height: 100vh;
	form {
		background: transparent;
	}
}

// html {
// 	height: 100%;
// }



.login-box {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 400px;
	max-width: 100%;
	padding: 40px;
	transform: translate(-50%, -50%);
	background-color: $backColor;
	box-sizing: border-box;
	box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
	border-radius: 10px;


	h4 {
		margin: 0 0 12px;
		padding: 0;
		color: #fff;
		text-align: center;
		display: block;
		min-height: 17px;
		font-weight: 400;
	}

	.user-box {
		position: relative;

		label {
			position: absolute;
			top: 0;
			left: 0;
			padding: 10px 0;
			font-size: 16px;
			color: #fff;
			pointer-events: none;
			transition: .5s;
		}

		.label_on_active_input {
			top: -16px;
			left: 0px;
			color: #03e9f4;
			font-size: 14px;
			padding: 0;
		}
		@mixin crutch_label_on_active_input{
			top: -16px;
			left: 0px;
			color: #03e9f4;
			font-size: 14px;
			padding: 0;
		}

		@mixin active_input_style {
			// 	cursor: pointer;
			// background-color: yellow !important;
			// color: green!important;
			background: $backColor;
			background-color: $backColor!important;
			-webkit-box-shadow: none;
			box-shadow: none;
		}

		input {
			width: 100%;
			padding: 10px 0px;
			// height: 17px;
			font-size: 16px;
			color: white;
			margin-bottom: 40px;
			border: none;
			border-bottom: 1px solid #fff;
			background-color: $backColor;
			background-clip: content-box;

			&:valid:not([value=""]) {
				@include active_input_style;

			}
			&:focus {
				@include active_input_style;
			}

			// &:valid {
			// 	background: pink;
			// }
			// &:-internal-autofill-previewed {
			// 	@include active_input_style;
			// }
			// &:-internal-autofill-selected {
			// 	@include active_input_style;
			// }
			// &:active {
			// 	@include active_input_style;
			// }

			// &:visited {
			// 	@include active_input_style;
			// }

			&:autofill {
				@include active_input_style;

			}
			// &:-webkit-autofill{
			// 	@include active_input_style;

			// }



		}
		input:-internal-autofill-selected ~label{
			@include crutch_label_on_active_input;
		}
		input:-internal-autofill-previewed ~label {
			@include crutch_label_on_active_input;
		}
		input:not([value=""]):valid ~label {
			// color: red;
			@include crutch_label_on_active_input;
		}
		// input:indeterminate ~label {
		// 	@include crutch_label_on_active_input;
		// }
		input:focus ~label {
			@include crutch_label_on_active_input;
		}



	}

	button {
		position: relative;
		display: inline-block;
		padding: 10px 20px;
		color: #03e9f4;
		font-size: 16px;
		text-decoration: none;
		text-transform: uppercase;
		overflow: hidden;
		transition: .5s;
		margin-top: 40px;
		letter-spacing: 4px;
		background: transparent;
		&:hover {
			background: #03e9f4;
			color: #fff;
			border-radius: 5px;
			box-shadow: 0 0 5px #03e9f4,
				0 0 25px #03e9f4,
				0 0 50px #03e9f4,
				0 0 100px #03e9f4;
		}
		span {
			position: absolute;
			display: block;
		}

		span:nth-child(1) {
			top: 0;
			left: -100%;
			width: 100%;
			height: 2px;
			background: linear-gradient(90deg, transparent, #03e9f4);
			animation: btn-anim1 1s linear infinite;
		}

		span:nth-child(2) {
			top: -100%;
			right: 0;
			width: 2px;
			height: 100%;
			background: linear-gradient(180deg, transparent, #03e9f4);
			animation: btn-anim2 1s linear infinite;
			animation-delay: .25s
		}

		span:nth-child(3) {
			bottom: 0;
			right: -100%;
			width: 100%;
			height: 2px;
			background: linear-gradient(270deg, transparent, #03e9f4);
			animation: btn-anim3 1s linear infinite;
			animation-delay: .5s
		}

		span:nth-child(4) {
			bottom: -100%;
			left: 0;
			width: 2px;
			height: 100%;
			background: linear-gradient(360deg, transparent, #03e9f4);
			animation: btn-anim4 1s linear infinite;
			animation-delay: .75s
		}
	}

}


@keyframes btn-anim1 {
	0% {
		left: -100%;
	}

	50%,
	100% {
		left: 100%;
	}
}


@keyframes btn-anim2 {
	0% {
		top: -100%;
	}

	50%,
	100% {
		top: 100%;
	}
}


@keyframes btn-anim3 {
	0% {
		right: -100%;
	}

	50%,
	100% {
		right: 100%;
	}
}


@keyframes btn-anim4 {
	0% {
		bottom: -100%;
	}

	50%,
	100% {
		bottom: 100%;
	}
}