/*defDecisions*/
@import "mixin/mixin.scss";
$DarkBrown: white;
$Orange: white;
.show{
	visibility: visible;
	opacity: 1;
}
.hide{
	visibility: hidden;
	opacity: 0;
}

.custom{
	border-top: 1px solid $grey_line;
	padding-top: 2.4rem;
	padding-bottom: 0.8rem;
	p{
		margin-bottom: 1.6rem;
	}
	.select_name{
		margin-bottom: 1.8rem;
	}
	input[type="radio"] {
   		display:none;
	}
	input[type="radio"] + label {
	    color: #0e0e0e;
	    font-size:14px;
	}
	input[type="radio"] + label span {
	    display:inline-block;
	    width: 2rem;
	    height: 2rem;
	    margin:-1px 14px 0 0;
	    vertical-align:middle;
	    cursor:pointer;
	    -moz-border-radius:  50%;
	    border-radius:  50%;
	    border: 1px solid #9aa0a7;
	    position: relative;
		i{
			background-color: #FFF;
			-webkit-border-radius: 50%;
			        border-radius: 50%;
			display: block;
			width: 10px;
  			height: 10px;
  			position: absolute;
  			@include PosAbsCent;

		}
	}


	input[type="radio"] + label span {
	    background-color:$DarkBrown;
	}

	input[type="radio"]:checked + label span{
	    background-color:$Orange;
	    i{
			background-color: #4179f8;
		}
	}

	input[type="radio"] + label span,
	input[type="radio"]:checked + label span i{
		-webkit-transition:background-color 0.4s linear;
		-o-transition:background-color 0.4s linear;
		-moz-transition:background-color 0.4s linear;
		transition:background-color 0.4s linear;
	}
}
.custom_checkbox_container{
	position: relative;
	input[type="checkbox"] {
   		display:none;
	}
	input[type="checkbox"] + label {
	    color: #0e0e0e;
	    font-size:14px;
	    cursor: pointer;
	}
	input[type="checkbox"] + label span {
	    display:inline-block;
	    width: 2rem;
	    height: 2rem;
	    margin:-1px 14px 0 0;
	    vertical-align:middle;
	    cursor:pointer;
	    width: 16px;
		height: 16px;
		border-radius: 2px;
		background-color: #ffffff;
	    border: 1px solid #d7d9dc;
	    position: relative;
		i {
			display: block;
			width: 10px;
  			height: 10px;
  			position: absolute;
  			@include PosAbsCent;
			opacity: 0;
			line-height: 0px;
			text-align: center;
		}
	}


	input[type="checkbox"] + label span {
	    background-color:$DarkBrown;
	}

	input[type="checkbox"]:checked + label span{
	    i{
			opacity: 1;
		}
	}

	input[type="checkbox"] + label span,
	input[type="checkbox"]:checked + label span i{
		-webkit-transition: opacity 0.4s linear;
		-o-transition: opacity 0.4s linear;
		-moz-transition: opacity 0.4s linear;
		transition: opacity 0.4s linear;
	}
}

.container_of_select_list{
	height: 35px;
	width: 177px;
}
.simple_select_list{
	width: inherit;
	height: inherit;
  	border-radius: 4px;
	position: relative;
	padding: 0px 0px 0px;
    transition: width 0.2s ease;
    min-width: max-content;
    max-width: 100%;
    padding-right: 15px;
    position: relative;
    background: #FFF;
    &:active{
    	.selected_item{
    		background: #d7d9dc;
    	}
    }

    .selected_item{
   		height: inherit;
   		width: inherit;
   		border-radius: 4px;
	    background-color: white;
	    border: solid 1px #d7d9dc;
	    position: relative;
	    padding-right: 16px;
	    padding-left: 16px;
	    text-align: left;
	    color: #9aa0a7;
	    /* margin-bottom: 3px; */
	    z-index: 2;
	    position: relative;
	    border-radius: 4px;
	    background: #FFF;
    	max-width: 100%;
    	font-size: 14px;

	    .triangle{
	    	position: absolute;
	    	right: 4px;
	    	height: 4px;
	    	line-height: 4px;
	    	@include PosAbsVertical;
	    }
    }
    .list{
    	visibility: hidden;
    	height: inherit;
    	width: inherit;
    	overflow: hidden;
    	position: absolute;
    	left: 0px;
    	padding-top: 7px;
    	padding-bottom: 7px;
    	transition: height 0.4s ease-in-out;
    	background: inherit;
    	max-width: 100%;
    	border-left: 1px solid #d7d9dc;
    	border-bottom: 1px solid #D7D9DC;
    	border-right: 1px solid #D7D9DC;

    	@extend .scroll;
    	li{
    		list-style: none;
    		display: flex;
    		height: 35px;
    		padding-right: 16px;
	    	padding-left: 16px;
	    	cursor: pointer;
	    	&:hover{
	    		background: $blue;
	    		font-weight: 700;
	    	}
    	}
    }
    .list_open{
		visibility: visible;
	    height: auto;
	    overflow-y: scroll;
	}
}
.select_list{
  	width: 177px;
  	border-radius: 4px;
	position: relative;
	padding: 0px 0px 0px;
    transition: width 0.2s ease;
    min-width: max-content;
    padding-right: 15px;
    position: relative;
    .no_style_btn{
    	width: 100%;
    	text-align: left;
    }
	.hidden_list{
		position: absolute;
		z-index: 2;
		list-style: none;
	}
	.hidden_select_list_NODATA{
		transition: max-height 0.4s ease, width 0.4s ease;
		position: absolute;
		z-index: 3;
		width: 100%;
		border-left: 1px solid #d7d9dc;
		border-right: 1px solid #d7d9dc;
		border-bottom: 1px solid #d7d9dc;
		-webkit-border-radius: inherit;
		        border-radius: inherit;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.33);
		background: #FFF;
		width: 283px;
		padding: 28px 24px 22px;
		text-align: center;

		&:first-child{
			font-size: 1.4rem;
			 color: #0e0e0e;
			 line-height: 20px;
		}
		p:nth-child(2){
			color: #9aa0a7;
			padding: 7px 0px 5px;
		}

	}
	.selected_value{
		position: relative;
		display: inline-block;
		width: auto;
		padding-right: 16px;
	}
    .select_triangle{
    	position: absolute;
    	right: 0px;
    	height: 4px;
    	line-height: 4px;
    	@include PosAbsVertical;
    }
}
.deactive{
	.hidden_list, .hidden_select_list_NODATA{
		opacity: 0;
		visibility: hidden;
		max-height: 0px;
	}

}
.active{
	.hidden_list, .hidden_select_list_NODATA{
		opacity: 1;
		visibility: visible;
		max-height: 750px;

	}
}

.new_transport, .type_task,  .route, .SimpleSelectList{
	.no_style_btn{
			line-height: 35px;
			text-align: left;
			padding-left: 12px;
			padding-right: 12px;
			min-width: max-content;
		}
	.hidden_list{
		border: solid 1px #d7d9dc;
		position: absolute;
		z-index: 3;
		opacity: 1;
		background: #FFF;
		list-style: none;
		overflow-y: scroll;
		max-height: 100px;
		width: 100%;
        transition: max-height 0.4s ease, width 0.4s ease, opacity 0.5s ease;
		@extend .scroll;
		&:before{
			display: none;
		}

	}

	.active{
		width: 285px;
		.hidden_list{
			height: auto;
			width: 285px;
			visibility: visible;
			opacity: 1;
			-ms-overflow-y: scroll;
			    overflow-y: scroll;
			-ms-overflow-x: hidden;
			    overflow-x: hidden;
			max-height: 200px;
		}
		.hidden_select_list_NODATA{
			height: auto;
			visibility: visible;
			opacity: 1;
			max-height: 200px;
			overflow: hidden;
		}
	}
	.deactive{
		.hidden_select_list_NODATA, .hidden_list{
			overflow: hidden;
			visibility: hidden;
			opacity: 0;
			max-height: 0px;
			padding: 0px;
		}
	}
}

.for_custom_checkbox{
	position: relative;
    text-align: center;
    width: 20px;
    display: inline-block;

}
.psevdo_label{
	    line-height: 17px;
    	vertical-align: top;
    	font-size: 13px;
    	a{
    		vertical-align: top;
    	}
}
.for_custom_checkbox{
		width: 16px;
		height: 16px;
		position: relative;
		margin-right: 11px;
	  	label {
			cursor: pointer;
			position: relative;
			border-radius: 4px;
			background-color: rgb(255, 255, 255);
			border: solid 1px rgb(154, 160, 167);
			z-index: 1;
			display: block;
			width: 100%;
			height: 100%;
			@include PosAbsCent;
		  //   &:after {

				// background-color: rgb(65, 121, 248);
				// opacity: 0;
				// z-index: 2;
  		// 		background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='12px' height='11px' viewBox='0 0 12 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 41.1 (35376) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EComplete%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Task' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Tasks' transform='translate(-1186.000000, -248.000000)' fill='%230E0E0E'%3E%3Cg id='task-1' transform='translate(20.000000, 159.000000)'%3E%3Cg id='controls' transform='translate(1124.000000, 81.000000)'%3E%3Cg id='complete' transform='translate(34.000000, 0.000000)'%3E%3Cg id='Complete' transform='translate(7.000000, 7.000000)'%3E%3Cpath d='M6.79578109,11.6087328 C6.4919271,12.0645599 5.88137671,12.1608722 5.44540743,11.8338502 L1.39973194,8.79917618 C0.957743544,8.46818487 0.868745879,7.84120132 1.20073717,7.4002129 C1.53172848,6.95822449 2.15871203,6.86922683 2.59970045,7.20021814 L5.75461766,9.56515608 L11.1674756,1.44536917 C11.4744675,0.985381242 12.0944513,0.86238447 12.5544392,1.16737647 C13.0144271,1.47436841 13.1384239,2.09435214 12.8314319,2.55434007 L6.79578109,11.6087328 Z' fill='white' id='Fill-1'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
				// background-position: center;
				// background-repeat: no-repeat;
				// background-size: 10px 8px;
		  //   }
		}
		input[type=checkbox] {
		    visibility: hidden;
		    &:checked + label{
		    	border-color: rgb(65, 121, 248);
		    	background-color: rgb(65, 121, 248);
				z-index: 2;
  				background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='12px' height='11px' viewBox='0 0 12 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 41.1 (35376) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EComplete%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Task' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Tasks' transform='translate(-1186.000000, -248.000000)' fill='%230E0E0E'%3E%3Cg id='task-1' transform='translate(20.000000, 159.000000)'%3E%3Cg id='controls' transform='translate(1124.000000, 81.000000)'%3E%3Cg id='complete' transform='translate(34.000000, 0.000000)'%3E%3Cg id='Complete' transform='translate(7.000000, 7.000000)'%3E%3Cpath d='M6.79578109,11.6087328 C6.4919271,12.0645599 5.88137671,12.1608722 5.44540743,11.8338502 L1.39973194,8.79917618 C0.957743544,8.46818487 0.868745879,7.84120132 1.20073717,7.4002129 C1.53172848,6.95822449 2.15871203,6.86922683 2.59970045,7.20021814 L5.75461766,9.56515608 L11.1674756,1.44536917 C11.4744675,0.985381242 12.0944513,0.86238447 12.5544392,1.16737647 C13.0144271,1.47436841 13.1384239,2.09435214 12.8314319,2.55434007 L6.79578109,11.6087328 Z' fill='white' id='Fill-1'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
				background-position: center;
				background-repeat: no-repeat;
				background-size: 10px 8px;
		    }
		}
}
