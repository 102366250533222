@import "mixin/mixin.scss";
@import "fonts.scss";
$placeholder: rgba(119, 115, 115, 0.8);

.page_name {
	font-family: $Roboto;
	padding-top: 25px;
	margin-bottom: 20px;


}

.page_name_client {
	@extend .page_name;
	display: inline-block;
	padding-right: 40px;
	width: 450px;
	min-width: 50%;
	max-width: 100%;
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 15px;

	.edit {
		opacity: 1;
		position: static;
		margin-left: 15px;
	}

	.goToClient {
		float: none;
		margin-left: 20px;
		margin-right: 15px;
	}

	.showQR {}

	.id_in_head {
		// padding-top: 5px;
		font-size: 1.6rem;
		font-weight: normal;
	}
}

.f_update_name {
	position: absolute;
	top: 15px;
	left: 0px;
	background: #FFF;
	line-height: 30px;
	z-index: 10;
	min-width: 400px;
	max-width: 100vw;
	width: 100%;
	padding: 5px 10px 5px 0px;

	.input,
	.btn_black {
		vertical-align: top;
		height: 30px;
		padding-top: 6px;
		padding-bottom: 6px;
		margin-right: 6px;
	}

	.close {
		// top: 2px;
		width: 26px;
		height: 29px;
		position: static;
	}
}

.right_of_name {
	display: inline-flex;
	float: right;
	text-align: right;
	// width: 20px;
	width: auto;
	margin-top: 25px;

	.one_switch {
		margin-right: 10px;
	}

	.btn_black {
		display: inline-block;
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}

	&:after {
		@include clear;
	}


}

.nameForm {
	font-size: 1.8rem;
	font-family: $Rbold;

	.info {
		font-family: $Roboto;
		opacity: 0.95;
	}
}

.form_block {
	display: flex;
	align-items: stretch;
	// margin-top: 50px;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-items: space-between;
	align-items: space-between;
	-webkit-align-content: flex-start;
	align-content: flex-start;
	@extend .one_block;

	form,
	.form_change_vehicle_name,
	.form_edit_vehicle_owner {
		display: block;
		width: 290px;
		vertical-align: top;
		margin-bottom: 20px;
		// margin-top: 0px;
		// margin: 0px 65px 20px 0px;
		max-width: 320px;

		// display: flex;
		// flex-wrap: wrap;
		//    align-items: flex-start;
		.input,
		textarea {
			width: 100%;
			margin-bottom: 15px;
			resize: vertical;
		}

		.form_message {
			margin-bottom: 5px;
			margin-top: 5px;
			color: brown;
			width: 100%;

			span {
				opacity: 0;
			}
		}

		.btn_black {
			min-width: 188px;
			margin-bottom: 0px;
			align-self: flex-end;
		}
	}

	.form_message_for_client {
		width: 580px;

	}

	.form_blocking {
		.nameForm {
			margin-bottom: 24px;
		}

		.calendar-input {
			margin-bottom: 15px;
			@include inputBorderRadius;
		}

		.last_btn {
			margin-top: 5px;
			margin-bottom: 10px;
		}

		.btn_black {
			min-height: 32px;
		}
		.select_blocking_date{
			margin-bottom: 15px;
		}
		button[name='blocked']{
			margin-bottom: 15px;
		}
		.select_blocking_input{
			margin-bottom: 7px;
			-webkit-flex-direction: column;
			    -ms-flex-direction: column;
			        flex-direction: column;
			     -webkit-justify-content: flex-end;
			             justify-content: flex-end;
			    .time_input_label{
			    	margin-bottom: 3px;
			    	margin-right: 0;
			    	display: block;
	    	        text-align: left;
	    	        width: 100%;
			    }
		}
		.blocking_date {
			text-align: left;
			display: flex;
			margin-bottom: 24px;
			-webkit-align-items: center;
			        align-items: center;
			.disable_planned_block{
				background: none;
				width: 30px;
				height: 30px;
				background: $blue;
				-webkit-border-radius: 2px;
				        border-radius: 2px;
				text-align: center;
				.deletedate{
					margin: auto;
					filter: invert(1)
				}
			}
			b{
				display: block;
			}
			div{
				margin-right: 27px;
			}
		}
	}

	.changePass {
		.btn_black {
			// margin-top:20px;
		}
	}

	.change_owner {
		.btn_black {
			margin-top: 45px;
		}
	}

}

.checkbox_container {
	margin-bottom: 15px;
}

.calendar {
	width: 100%;
}

.calendar-input {
	width: 100%;
	height: 30px;
	border-radius: 2px;
	background-color: #ffffff;
	border: solid 1px #d7d9dc;
	padding-left: 15px;

	@include placeholder {
		opacity: 0.7;
	}
}

.calendar-input-invalid {
	box-shadow: inset 0px 0px 17px -5px rgba(240, 36, 36, 0.88);
}

.calendar-header {
	height: 32px;
	position: static;
}

.calendar-fast_navigation{
	height: inherit;
	display: flex;
	flex-flow: column;
	position: relative;
}

.calendar-my-select {
	width: 143px;
	height: inherit;
	font-family: $Rbold;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #0e0e0e;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

$calendar_btn_width: 30px;

@mixin calendarHeaderBtnStyles($left) {
	position: absolute;
	font-family: $Roboto;
	width: $calendar_btn_width;
	height: 30px;
	left: $left;
	text-align: center;
	border-radius: 2px;
	background-color: #fffc;
	border: solid 1px #d7d9dc;
	cursor: pointer;
	line-height: 30px;
	top: 0px;
	bottom: 0;
	margin: auto;
	display: block;

	&:hover {
		background-color: #f1f1f1;
	}

	&:before {
		content: 'ᐊ';
		font-family: $Roboto;
	}
}

.calendar-next-month-btn,
.calendar-next-year-btn {
	transform: rotate(180deg);
}

.calendar-prev-year-btn {
	$left: calc(50% + 5px);
	@include calendarHeaderBtnStyles($left);
}



.calendar-next-year-btn {
	$left:calc(100% - #{$calendar_btn_width});
	@include calendarHeaderBtnStyles($left);
}

.calendar-prev-month-btn {
	@include calendarHeaderBtnStyles(0%);
}

.calendar-next-month-btn {
	$left:calc(50% - #{$calendar_btn_width} - 5px);
	@include calendarHeaderBtnStyles($left);
}

.calendar-month-select {
	// position: absolute;
	// z-index:1;
	// left: 38px;
	width: 100px;
	text-align: center;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.calendar-year-select {
	position: absolute;
	z-index: 1;
	right: 55px;
}

.calendar-body {
	thead {
		line-height: 39px;
	}

	.calendar-column-header-inner {
		font-family: $Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 18.5px;
		letter-spacing: normal;
		text-align: center;
		color: #9aa0a7;
	}

	.calendar-cell {
		font-family: $Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 18.5px;
		letter-spacing: normal;
		text-align: center;
		color: #424f5b;
		cursor: pointer;
	}

	.calendar-cell {
		width: 32px;
		height: 32px;
		background-color: #ffffff;
		background-color: var(--white);
		border: solid 1px rgba(65, 79, 91, 0.2);
	}

	.calendar-last-month-cell,
	.calendar-next-month-btn-day {
		visibility: hidden;
	}

	// width: 143px;
	// height: 16px;
	// font-family: Roboto;
	// font-size: 14px;
	// font-weight: bold;
	// font-style: normal;
	// font-stretch: normal;
	// line-height: normal;
	// letter-spacing: normal;
	// text-align: center;
	// color: #0e0e0e;
	.calendar-table {
		width: 100%;
	}

	.calendar-selected-day {
		background-color: #f1f1f1;
	}
}

.calendar-month-panel-body {
	padding-top: 60px;
}

.calendar-month-panel-table {
	width: 72%;
	background: #FFF;
	position: relative;
	z-index: 2;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

	.calendar-month-panel-tbody {
		background: #FFF;
	}

	tr,
	td {
		background: inherit;
		cursor: pointer;
	}

	tr {
		height: 32px;
		width: inherit;
	}

	td {
		width: 32px;
		height: inherit;
		text-align: center;
		border: solid 1px rgba(65, 79, 91, 0.2);
	}

	.calendar-month-panel-selected-cell {
		background-color: #f1f1f1;
	}
}

.calendar-month-panel-year-select {
	display: none;
}

.calendar-year-panel {
	display: none;
}

.deletedate {
	width: 15px;
	display: inline-block;
	// margin-left: 15px;
	cursor: pointer;
	transition: all 0.4s ease;

	&:hover {
		opacity: 0.7;
	}
}

.formError_calendar {
	.calendar-input {
		box-shadow: inset 0px 0px 17px -5px rgba(240, 36, 36, 0.88);
		@include inputBorderRadius
	}
}

.goToClient {
	width: 30px;
	height: 30px;
	float: right;
	background: #FFF;
	border: 1px solid $border_block;
	border-radius: 2px;
	cursor: pointer;
}

.goToClientIcon {
	width: 100%;
}