@import "mixin/mixin.scss";
.form_header{
	display: block;
	margin-bottom: 10px;
}
#text_notif{
	display: block;
	width: 100%;
	height: 200px;
}
.form_update_notification{
	padding-bottom: 10px;
}
.what_is_new_content{
	.pop_up_footer{
		text-align: right;
	}
	.delete_btn, .edit_btn{
		width: 22px;
		height: 22px;
		background: none;
		border: none;
	}
}
.one_update_notif{
	padding: 15px;
	position: relative;
	border: 1px solid $grey_line;
	margin-top: 15px;
	.update_notif_date{
		margin-bottom: 10px;
		font-weight: bold;
	}
	.delete_btn, .edit_btn{
		position: absolute;
		top: 10px;
	}
	.delete_btn{
		right: 15px;

	}
	.edit_btn{
		right: 40px;
	}
}
.delete_btn{
	cursor: pointer;
}