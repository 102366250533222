@import "mixin/mixin.scss";
@import "fonts.scss";

.device_page {
	position: relative;
	.delete_device_btn{
		position: absolute;
		right: 25px;
		top: 15px;
	}
	.link_to_owner_to_on_device_page{
		display: inline-block;
		margin-right: 10px;
	}
	.edit_device_owner_form_content{
		padding: 20px;
		.hint_container{
			margin-bottom: 40px;
		}
		// padding-left: 20px;
	 //    padding-right: 20px;
	}
	.add_info {
		float: none;
	}
	.edit_device_type_form_content{
		@extend .edit_device_owner_form_content;
		.simple_select_list{
			margin-bottom: 125px;
		}
	}
	.page_name_client {
		display: block;
		padding-top: 0px;
		padding-bottom: 15px;
	}

	.device_info {
		display: inline-block;
		p{
			margin-bottom: 7px;
		}

		b{
			margin-right: 10px;
		}
		.counter_switch{
			display: flex;
			margin-bottom: 4px;
		}
		.text_info{
			display: inline-block;
			padding-right: 10px;
		}
		.line_name{
			display: inline-block;
			padding-right: 10px;
			min-width: 216px;
		}
		.name_client{
			// a{
			// 	display: block;
			// }
		}
	}

	[name='disconect'], [name='ignition_settings_btn'] {
		margin-bottom: 15px;
		margin-right: 15px;
		border-radius: 4px;
		padding: 5px 10px;
	}
	.right_part_on_device_page{
		float: right;
	}
	.current_info {
		display: inline-block;
		width: 500px;
		vertical-align: top;
		padding-top: 0px;
		text-align: right;
		border: 1px solid black;
		border-top: none;
		padding-right: 10px;
		text-align: right;

		h3 {
			margin-bottom: 15px;
		}

		.date_for {
			border-top: none;
			padding-top: 0px;
			text-align: right;
			padding-right: 0px;

			div {
				text-align: right;
			}

			p {
				text-align: inherit;
			}

		}

		.btn_black {
			margin-top: 15px;
			display: inline-block;
		}
	}
	.add_info {
		line-height: inherit;
		padding-left: 10px;
		.btn_black{
			padding: 0;
			display: inline-block;
			background: none;
			border: none;
		}
	}
	.answer_line{
		margin-bottom: 10px;
	}
}
.label_on_form{
	margin-bottom: 10px;
	display: inline-block;
	padding-right: 15px;
}
.edit_phone_form{
	padding: 15px;

	.btn_black{
		display: block;
	}
}
.edit_imei_form{
	padding: 15px;
	.input{
		margin-bottom: 15px;
		-webkit-border-radius: 4px;
		        border-radius: 4px;
	}

	.btn_black{
		display: block;
		-webkit-border-radius: 4px;
		        border-radius: 4px;
	}
}
.react-phone-number-input{
	width: 150px;
	display: inline-block;
	margin-bottom: 15px;
	.react-phone-number-input__input{
		padding: 10px;
		border: 1px solid #C5D2E0;
		-webkit-border-radius: 4px;
		        border-radius: 4px;
	}
}
.tubs_container {
	text-align: center;
	margin-top: 15px;
	display: flex;
	-webkit-align-items: stretch;
	        align-items: stretch;
	.tub_button {
		padding: 8px 13px;
		width: 100%;
		border-radius: 0;
		background-color: white;
		font-size: 14px;
		color: $blue;
		border: 1px solid $blue;
		cursor: pointer;
		text-decoration: none;
		transition: all 0.4s ease;

		&:hover {
			opacity: 0.87;
			color: white;
			background-color: $blue;
		}
		&:active {
			transform: scale(1.01);
		}

		&:first-child {
			// border-radius: 100px 0px 0px 100px;
		}

		&:last-child {
			// border-radius: 0px 100px 100px 0px;
		}
	}
}
.device_answer {
	padding-top: 5px;
	padding-bottom: 5px;
}

.interval_input {
	display: inline-block;
	margin-bottom: 15px;
	width: 60px;
	margin-right: 15px;

	.time_input,
	label {
		display: block;
		text-align: center;
	}
}

.time_input {
	width: 60px;
	height: 30px;
	border-radius: 4px;
	background-color: #ffffff;
	border: solid 1px #d7d9dc;
	padding-left: 15px;
	line-height: 30px;

}

.calendar_container {
	width: 315px;

	.calendar-date-input-wrap {
		margin-bottom: 15px;
	}
}

.device_form {
	width: 357px;

	.time_input_container{
		width: 260px;
		border: solid 1px #708090;
		border-radius: calc(4px * 2);
		.psevdo_time_input{
			border: none;
			border-radius: inherit;
			width: 100%;
		}
	}
	.react-phone-number-input{
		width: 260px;
	}
	#phone_number{
		@extend .time_input_container;
		@extend .input;
	}
	.time_input_show_button{
		margin-right: 0px;
		margin-left: auto;
	}
	input {
		float: none;
		width: 260px;
	}
	.checkbox_input{
		display: inline-block;
		width: 25px;
	}
	.track_activation_label{
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		    margin-bottom: 15px;
	}
	.belongsTo {
		float: none;
	}

	.btn_black {
		margin-left: 0px;
	}
}

.ignition_settings_form{
	width: 440px;
	.nameForm{
		margin-bottom: 7px;
	}
	.footer_form{
		display: flex;
	}
}

.transport_form {
	.period2 {
		float: right;
	}

	.form_text_area {
		height: 76px;
	}

	input[type="radio"] {
		margin-left: 15px;
		vertical-align: top;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}

	}

	.radioP {
		line-height: 1.4rem;
		margin-bottom: 1.4rem;

		label {
			padding-left: 15px;
			vertical-align: top;
			line-height: 1.3rem;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}

		}
	}

	.title,
	.units {
		float: right;
	}

	.addsensor {
		padding: 9px 21px;
		display: block;
		margin-left: 0px;
		margin-bottom: 15px;
	}
}

.edit_virtual{
	.units {
		margin-bottom: 15px;
	}

	.title {
		margin-bottom: 15px;
	}

	.container_select_virtualSensorType {
		$height: 250px;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		height: $height;
		border: 1px solid $border_block;
		margin-bottom: 15px;

		.virtual_sensor_type_group,
		.one_virtual_sensor_type {
			display: block;
			padding: 5px 10px;
			width: 100%;
			text-align: left;
			background: #FFF;
		}

		.container_list_sensorTypes {
			padding-left: 15px;
		}
		.container_list_typesOfsensorTypes{
			border-right: 1px solid $border_block;
			
		}
		.container_list_typesOfsensorTypes,
		.container_list_sensorTypes {
			height: calc(#{$height}-2px);
			width: 50%;
			overflow-y: auto;
			padding: 7px;
			padding-top: 7px;
			padding-bottom: 7px;
			
			scroll-snap-type: y mandatory;
			overscroll-behavior-y: contain;
			@extend .scroll;
		}

		.radioP {
			padding-top: 2px;
			padding-bottom: 2px;
			display: flex;
			scroll-snap-align: center;

			input {
				margin-right: 6px;
			}
		}
	}
}

.virtual_sensors_types_select_list{
	padding-top: 15px;
	margin-bottom: 15px;
	overflow-y: auto;
    scroll-snap-type: y mandatory;
    overscroll-behavior-y: contain;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 250px;
    @extend .scroll;
	.radio_buttons_group {
		margin-bottom: 8px;
		scroll-snap-align: center;
		background: $border_block;
		padding: 9px;
		border-radius: 3px;
		width: calc(50% - 4px);
	}

	.block_name {
		font-weight: medium;
		text-transform: uppercase;
		margin-bottom: 3px;
		color: #708090;
	}

	.radioP {
		padding-top: 2px;
		padding-bottom: 2px;
		display: flex;
		input{
			margin-right: 6px;
		}
	}
}

.sensor_field_container {
	padding: 20px 20px 22px;

	.wrap {
		display: inline-block;
		margin-bottom: 15px;
	}
	.clear{
		margin-bottom: 15px;
	}
	.primari_btn {
		float: right;
	}

	.secondary_btn {
		float: right;
		margin-right: 20px;
	}
	.calibration_select_list{
		z-index: 2;
		width: 260px;
		min-width: 260px;
		.selected_item{
			height: 27px;
			@include collapsedText;
		}
	}
	.virtual_sensors_select_list{
		@extend .calibration_select_list;
		z-index: 1;
	}
	
}

.notif {
	height: 17px;
}

.sensor_field {
	position: relative;
	margin-bottom: 45px;
	// border-top: 1px solid #d7d9dc;
	// border-bottom: 1px solid #d7d9dc;
	padding-top: 10px;
	padding-bottom: 0px;
}

.remove_field {
	position: absolute;
	right: -10px;
	top: -13px;
	cursor: pointer;
	background: #FFF;
	font-weight: 600;

	&:hover {
		opacity: 0.65;
	}
}

.sensors {
	padding: 20px 0px;
}

.transport_page_sensor {
	@extend .one_block;
	.sensor_field {
		width: 600px;

		// padding-right: 50px;
		.input {
			margin-bottom: 15px;
		}
	}
}

.existing {
	padding-top: 15px;

	input[name='id'],
	input[name='fracDigits'] {
		width: 215px;
	}

	input[name='fracDigits'] {
		margin-left: 43px;
	}

	.inputlabel {
		line-height: 30px;
		padding-right: 15px;
	}

	.btn_black {
		margin-bottom: 15px;
	}

	.deletedate {
		position: absolute;
		right: 5px;
		bottom: 19px;
		width: 25px;
	}
}

.label_textarea {
	display: block;
	margin-bottom: 10px;

	span {
		margin-left: 20px;
	}
}

.textarea_container {
	display: inline-block;
	padding: 0px 50px 25px;
}

.sensor_types,
.update_sensor {
	padding-bottom: 15px;

	

	.input_block {
		text-align: left;
		display: inline-block;
		width: 40%;
		vertical-align: top;
		padding-top: 25px;

		p {
			margin-bottom: 19px;

			input {
				float: right;
			}
		}
	}

	.inputlabel {
		line-height: 30px;
		padding-right: 15px;
	}

	.input {
		margin-right: 32px;
	}

	.btn_black {
		display: block;

	}

	.btn_black {
			display: inline-block;
			margin-right: 15px;
		}

		input {
			margin-bottom: 19px;
		}
}

.update_sensor {
	// @include PosFixCent;
	max-height: 100vh;
	width: 650px;
	height: 500px;
	background: #FFF;
	// padding: 15px;
	// padding-top: 35px;
	// border: $border;
		padding-top: 0;
		width: 600px;
		margin: auto;
	.form_text_area{
		margin-bottom: 19px;
	}
	.counter_switch{
		display: flex;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		margin-bottom: 12px;
	}
	// .name_select_list, .simple_select_list{
	// 	display: inline-block;
	// }
	.footer_add_calibration{
		padding-top: 15px;
		text-align: right;
	}
}

.existings_sensorType {
	// padding: 15px 0px;
	position: relative;
	z-index: 1;
	transition: all 0.4s ease;
	@extend .one_block;
	// overflow: hidden;
	// min-height: 100%;
	textarea[name='calibrationTable'] {
		height: 177px;
	}

	.hidebtn {
		left: 400px;
	}

	.calibration_cell {
		position: relative;
		// overflow: visible;
		.shorted_calibration_info{
			height: inherit;
			overflow: hidden;
			width: 100%;
			max-width: 130px;
			@include collapsedText;
		}
		.table_view {
			display: none;
			position: absolute;
			opacity: 0;

			width: 150px;
			max-height: 300px;
			min-height: 100px;
			height: auto;
			overflow: scroll;
			bottom: 0px;
			right: 0px;
			padding-left: 15px;
			border-right: 1px solid $border_block;
			border-left: 1px solid $border_block;
			border-bottom: 1px solid $border_block;
			background: white;
			@include transitionall;
		}

		/* &:hover {
			z-index: 2;
			// overflow: visible;

			.table_view {
				display: block;
				opacity: 1;
			}
		} */

	}
}

.connect_user {
	padding-top: 15px;
	padding-bottom: 15px;

	.input {
		margin-right: 15px;
	}
}

// .existings_sensorType, .existing_sensors{
// 	height: 0px;
// 	overflow: hidden;
// }

.real_sensors {
	@extend .one_block;
	.nameForm {
		margin-bottom: 25px;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		.btn_plus {
			margin-left: 15px;
		}
	}
}

.add_realSensor_form {
	padding: 10px 25px 25px;
	text-align: center;

	.wrap {
		display: inline-block;

		margin-bottom: 15px;
		text-align: left;
	}
	.reverse{
		min-width: 260px;
		display: block;
		input{
			margin-right: 10px;
		}
	}
	.hint_data {
		text-align: left;
	}

	.last {
		margin-bottom: 20px;
	}
}

.existing_sensors {
	// padding: 15px 0px;
	// position: relative;
	z-index: 1;
	transition: all 0.4s ease;
	overflow: hidden;

	.nameForm {
		margin-bottom: 25px;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		.btn_plus {
			margin-left: 15px;
		}
	}

	.existing {
		background: #FFF;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
		padding: 15px 15px 0px;

		div {
			&:before {
				@include clear;
			}
		}

	}
}

.hidebtn {
	position: absolute;
	top: 15px;
	left: 250px;
	font-size: 1.8rem;

	&:hover {
		opacity: 0.8;
		cursor: pointer;
	}
}

.Mhide {
	max-height: 50px;
}

.Mshow {
	max-height: 2000px;
	overflow: auto;
}

.confirmText_container {
	padding: 36px 20px 22px;

	.confirm_text {
		text-align: center;
		font-size: 16px;
		color: #0e0e0e;
		margin-bottom: 36px;

	}

	@include clear;

	button {
		float: right;
		margin-left: 15px;
	}
}

.mount_form {
	position: relative;
	// z-index: 1;
	padding-bottom: 20px;
	padding-top: 15px;
	margin-bottom: 15px;
	// border-bottom: 1px solid $black;
	.time_input_container{
		display: flex;
		width: 400px;
		max-width: 100%;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		margin-bottom: 10px;
	}
	.input,
	.calendar-input {
		border-color: $blue;
		margin-bottom: 15px;
	}

	.form_message {
		text-align: left;
		padding-left: 5px;
	}

	.addsensor {
		display: block;
		margin-bottom: 15px;
	}

	.close {
		width: 25px;
		height: 25px;
		left: 750px;
		top: 24px;
		z-index: 1000;
	}

	// label{
	// 	padding-left: 15px;
	// 	vertical-align: top;
	// 	line-height: 1.3rem;
	// 	cursor: pointer;
	// 	&:hover{
	// 		opacity: 0.8;
	// 	}

	// }
	.name_switch {
		padding-left: 15px;
	}

	.one_switch {
		margin-bottom: 15px;
	}

	.form_message {
		margin-bottom: 5px;
	}
}

.new_sensor_mapping{
	.nameForm{
		display: inline-block;
		padding-right: 15px;
		padding-bottom: 15px;
	}
	.primari_btn {

	}
	.addsensor{
		margin-left: 15px;
	}

}

.sensor_mapping {
	border: 1px solid $border_block;
	padding: 10px;
	-webkit-border-radius: 4px;
	        border-radius: 4px;
	.hint_container,
	.input {
		margin-bottom: 10px;
	}

	.aggregate {
		max-width: 528px;
		padding-bottom: 1px;
		margin-bottom: 15px;
	}

	.radioP {
		line-height: 1.4rem;
		margin-bottom: 1.4rem;
	}

	input[type="radio"] {
		margin-left: 15px;
		vertical-align: top;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}

	}

	.btn_black {
		display: inline-block;
	}

	.input {
		display: block;
	}

	.addsensor {
		margin-bottom: 10px;
	}
}

.edit_device_on_transport {
	&:nth-child(odd) {
		background-color: #f9f9f9;
	}

	padding-top: 0px;

	.btn_black {
		margin-right: 15px;
	}

	.addsensor {
		display: inline-block;
	}

}

.container_calendar {
	width: 320px;

	.calendar-date-panel {
		position: absolute;
		width: 320px;
		left: 450px;
		background: white;
		top: 47px;
		z-index: 20;
	}

	.calendar-input-wrap {
		width: 260px;
	}
}

.name_form_block {
	font-size: 1.4rem;
	font-family: Roboto-Bold, sans-serif;
	margin-bottom: 15px
}

.hint_container {
	position: relative;
}

.hint_data {
	position: absolute;
	background: white;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px #c9cbce;
	width: 260px;
	top: 30px;
	padding: 10px 0px;
	z-index: 20;
	max-height: 260px;
	overflow-y: auto;
	@extend .scroll;
	p {
		padding-left: 20px;
		max-width: 100%;
		@include collapsedText;
		margin-bottom: 4px;
		&:hover {
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
			cursor: pointer;
		}
	}
}

.edit_device_on_transport {
	border-top: 3px solid $grey_firm_style;
	.addsensor {
		margin-bottom: 15px;
	}
}

.dateIntervals {
	max-width: 700px;

	.nameParagraph {
		display: inline-block;
	}

	.container_calendar {
		display: inline-block;
		float: right;
	}

}

.edit_device_on_transport_container {
	// padding: 15px 0px;
	// border-top: 1px solid rgba(0, 0, 0, 1);
	border-bottom: 1px solid rgba(0, 0, 0, 1);
	@extend .one_block;
	.nameForm {
		font-size: 2.4rem;
	}

	.nameParagraph {
		font-size: 1.8rem;
	}
}

.deviceSensors_block {
	padding-left: 15px;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	margin-bottom: 10px;

	.detectorVSsensorType:last-child {
		padding-bottom: 0px;
	}
}

.detectorVSsensorType {
	padding-bottom: 10px;
}

.one_detector,
.date_block {
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	padding-left: 15px;
	margin-bottom: 15px;

	.addsensor {
		margin-bottom: 0px;
	}
}

.date_block {
	margin-top: 15px;
}

.small_height {
	line-height: 1.8rem;
}

.margin_bottom {
	margin-bottom: 15px;
}

.remove_equipment {
	margin-left: 60px;
}

.sensor_mapping {
	width: 528px;
	input{
		margin-right: 10px;
	}
}

.name_client {
	display: block;
	margin-top: 0px;
	color: black;
	font-size: 1.8rem;
	margin-bottom: 1.5rem;

	a {
		color: inherit;
		font-size: inherit;
		display: block;
	}
}

.command_to_device {
	.btn_black {
		vertical-align: top;
		margin-left: 15px;
	}

	.input {
		vertical-align: top;
	}
}

.sub_name {
	padding: 10px 0px;
}

.device_page {
	.page_name_client {
		margin-bottom: 10px;
		margin-top: 15px;
	}

	.name_client {
		margin-bottom: 15px;
	}
}

.command_history {
	padding-left: 15px;
	// padding-top: 15px;
	.command_name{
		text-decoration: underline;
		cursor: pointer;
	}
	li {
		margin-bottom: 10px;
	}

	span {
		padding-right: 10px;
	}
}

.calibrationInfo {
	position: fixed;
	background: #FFF;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	-webkit-border-radius: 3px;
	border-radius: 3px;
	padding: 15px;
	z-index: 5;

	.description {
		height: 50px;
		overflow: auto;
		margin-left: 15px;
		padding-left: 15px;
		resize: none;
	}

	.key {

		i {
			font-style: normal;
			vertical-align: top;
		}

		span {
			font-family: $Rmedium;
		}
	}
}

.detail_socket_info {
	padding: 15px;

	.selected_mapping_container {
		width: 50%;
		padding-left: 20px;
		
	}

	.add_realSensor_form {
		padding-left: 0;
	}

	.mapping_info_container {
		display: flex;
		-webkit-align-items: space-between;
		align-items: space-between;
	}

	.mapping_time_line_container {
		width: 50%;

		.btn_item_in_time_line {
			padding-top: 7px;
			padding: 7px 10px;
			-webkit-border-radius: inherit;
			border-radius: inherit;
			outline: none;
			border: 1px solid black;
			background: rgb(112, 128, 144);
			color: white;

		}

		.time-line-ctnr {

			// width: 50%;
			.time-line>li {
				.time-line-item {
					display: flex;
					-webkit-align-content: center;
					align-content: center;
					flex-direction: row-reverse;
					-webkit-align-items: center;
					align-items: center;
				}
			}
		}
	}

	.form_name,
	.tubsList {
		display: inline-block;
		margin-bottom: 15px;
	}

	.form_name {
		margin-right: 15px;
	}

	.detail_socket_info_tub_header {
		margin-bottom: 15px;
		font-size: 18px;
	}

	.add_btns_block {
		margin-bottom: 15px;
		display: inline-block;
	}

	.btn_list {
		padding-top: 15px;
		padding-bottom: 15px;

		&:after {
			@include clear;
		}

		.wrapper {
			display: inline-block;

			&:first-child {
				.btn_realsenor {
					border-radius: 15px 1px 1px 15px;
				}
			}

			&:last-child {
				.btn_realsenor {
					border-radius: 1px 15px 15px 1px;
				}
			}
		}
	}

	.btn_realsenor {
		padding: 7px 10px;
		-webkit-border-radius: inherit;
		border-radius: inherit;
		outline: none;
		border: 1px solid black;
		-webkit-transition: all 0.4s ease-in;
		-moz-transition: all 0.4s ease-in;
		-ms-transition: all 0.4s ease-in;
		-o-transition: all 0.4s ease-in;
		transition: all 0.4s ease-in;
		color: white;

		&:hover {
			background: $blue;
			color: white;
			cursor: pointer;
		}
	}

	.primari_btn {
		// margin: 3px;
		-webkit-border-radius: 1px;
		border-radius: 1px;
	}

	.sensor_detail {
		padding-top: 15px;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		width: 100%;
		animation: fadein 0.7s ease-in-out;

		.sensor_info {
			min-width: 280px;

			&>b {
				display: block;
				margin-bottom: 12px;
			}

			&>p {
				margin-bottom: 7px;
			}
		}

		.btns_block_in_sensor_info {
			.primari_btn {
				margin-right: 13px;
				margin-bottom: 8px;
			}
		}

	}

	.change_real_sensor,
	.change_period_form,
	.change_period_form {
		-webkit-animation: fadein 0.7s ease-in-out;
		/* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 0.7s ease-in-out;
		/* Firefox < 16 */
		-ms-animation: fadein 0.7s ease-in-out;
		/* Internet Explorer */
		-o-animation: fadein 0.7s ease-in-out;
		/* Opera < 12.1 */
		animation: fadein 0.7s ease-in-out;
	}
}

.change_real_sensor {
	text-align: left;
	.wrap{
		display: block;
		max-width: 260px;
	}
}

.tubsList {
	.info_tubs {
		padding: 5px 8px;
		color: white;

		&:hover {
			background: $blue !important;
			cursor: pointer;
			color: white;
		}
	}
}



.change_period_form {
	padding-top: 15px;

	.wrap {
		display: inline-block;
		margin-bottom: 15px;
	}
	.select_list{
		width: 260px;
	}
}

.realSensors_selectList {
	width: 100%;
}

.filters_point {
	display: none;
}

.filters {
	background-color: #ffffff;
	box-shadow: 0 2px 4px 0 rgba(41, 64, 86, 0.17);
	padding: 10px 17px;
	opacity: 0.99;
	z-index: 4;
	position: relative;

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.transport_search_form {
	display: inline-block;
}

.space_between {
	display: flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: center;
	align-items: center;
}

.filter_transport {
	display: inline-block;
	position: relative;
	z-index: 3;
	min-width: 209px; //209px;
	margin-left: 30px;
	margin-right: auto;


	span {
		color: #9aa0a7;
		margin-right: 8px;
	}

	.option_filter {
		background: transparent;
		cursor: pointer;
		width: 100%;
		text-align: left;
		padding-left: 23px;
		padding-top: 11px;
		padding-bottom: 11px;
		font-size: 1.4rem;

	}

	.selected_value {
		background: transparent;
		cursor: pointer;
		position: relative;
	}

	.deactive {
		visibility: hidden;
		max-height: 0px;
		opacity: 0;

	}

	.active {
		opacity: 1;
		max-height: auto;
	}

	.select_triangle {
		color: #0E0E0E;
		font-size: 10px;
		position: absolute;
		right: -25px;
		height: 4px;
		position: absolute;
		z-index: 15;

		&:before {
			content: '\25B2';
			display: block;
			position: absolute;
			color: white;
			top: 10px;
			width: 0px;
			height: 0px;
			left: -4px;
			text-shadow: 0px -2px 1px #969696;
			background: transparent;
		}

	}

	.triangle_bottom {
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		border-top: 5px solid black;
		display: block;
		width: 7px;
		height: 4px;
		top: 7px;

		&:before {
			opacity: 0;
		}
	}

	.triangle_top {
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		border-top: none;
		border-bottom: 5px solid black;
		top: 6px;

		&:before {
			opacity: 1;
		}
	}

	li {
		position: relative;
		// padding-right: 46px;
		list-style: none;
		@include transitionall;

		&:hover {
			background-color: #f5f5f5;

			.edit_svg {
				opacity: 1;
			}
		}
	}

	.edit_svg {
		position: absolute;
		width: 28px;
		border-radius: 50%;
		right: 9px;
		padding: 6px;
		top: 0px;
		bottom: 0px;
		margin: auto;
		cursor: pointer;
		@include transitionall;
		opacity: 0;
	}
	.hidden_select_list {
		@extend .scroll;
		overflow-y: scroll;
		max-height: 70vh;
	}

}

.hidden_select_list {
	position: absolute;
	top: 24px;
	left: -12px;
	border-radius: 1px;
	background-color: #ffffff;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px #c9cbce;
	min-width: 211px;
	padding: 14px 0px;
	width: max-content;

}

.hidden_select_list_NODATA {
	width: 283px;
	padding: 28px 24px 22px;
	text-align: center;

	&:first-child {
		font-size: 1.4rem;
		color: #0e0e0e;
		line-height: 20px;
	}

	p:nth-child(2) {
		color: #9aa0a7;
		padding: 23px 0px 28px;
	}
}

.createGroup {
	border-radius: 100px;
	background-color: #1d2329;
	padding: 7px 20px;
	font-family: $Roboto;
	font-weight: 400;
	font-size: 13px;
	color: #ffffff;
	cursor: pointer;
}

.newGroup {
	border-top: #EAEAEA 1px solid;
	height: 47px;
	padding-left: 48px;

}

.filter_events {
	display: inline-block;
	float: right;

	span {
		vertical-align: top;
		line-height: 22px;
	}
}

@media screen and (max-width: 800px) {}

@media screen and (max-width: 767px) {
	.filters {
		position: relative;

		// z-index: 2;
		.container {
			flex-direction: column;
			align-items: start;
		}

		.filters_point {
			position: absolute;
			right: 8px;
			top: 15px;
			display: block;
		}
	}

	.show_filters {
		transform: rotate(90deg)
	}

	.filter_events {
		visibility: hidden;
		opacity: 0;
		height: 0px;
		// margin-top: 15px;
	}

	.filter_transport {
		visibility: hidden;
		opacity: 0;
		height: 0px;
		display: none;
		// margin-top: 15px;
	}

	.filter_transport {
		position: relative;

	}

	.hidden_select_list {
		right: 0px;
		left: auto;
		top: 24px;
	}

}

.head_edit_group {
	border-bottom: 1px solid #eaeaea;
	height: 5.2rem;
	line-height: 5.2rem;
	padding-left: 24px;

	&:after {
		@include clear;
	}

	span {
		float: left;
		display: inline-block;
		font-size: 16px;
		letter-spacing: 0.2px;
		color: #0e0e0e;
	}

	.close {
		width: 16px;
		float: right;
		margin-right: 14px;
		margin-top: 19px;
		cursor: pointer;
		visibility: inherit;
		right: -2px;
		top: -17px;

		&:hover {
			transform: rotate(360deg) scale(1.2);
		}
	}
}

.edit_group {
	position: absolute;
	width: 446px;

	background: #ffffff;
	top: 50px;
	left: 100%;
	border-radius: 2px;
	background-color: #ffffff;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);

	.group_name_input {
		margin: 24px auto 22px;
		width: 100%;
		width: 398px;
		height: 36px;
		display: block;
		color: #0e0e0e;
		padding-left: 17px;
		border: solid 1px #d7d9dc;
		border-radius: 81px;
	}
}

.help_div_edit_group {
	background-color: #f5f5f5;
	padding-top: 8px;
	padding-right: 3px;
}

.list_edit_group {
	list-style: none;
	width: 100%;
	height: 347px;
	overflow-y: scroll;
	background-color: transparent;

	li {
		width: 430px;
		margin-left: 4px;
		padding-left: 20px;
		cursor: pointer;
		height: 40px;
		font-size: 1.6rem;
		line-height: 4.2rem;
		color: #9aa0a7;
		background-color: transparent;
		border: solid 1px transparent;
		border-radius: 3px;
		margin: 0px auto 2px;
		margin-bottom: 2px;
		position: relative;

		b {
			color: #0e0e0e;

		}

		svg {
			opacity: 0;
			position: absolute;
			right: 14px;
			top: 14px;
			transition: all 0.4s ease-in;
		}

		&:hover {
			background-color: #ffffff;
			border-color: #dfe6ec;
		}
	}

	.activeStyle {
		background-color: #ffffff;
		border-color: #dfe6ec;

		&:hover {
			background-color: rgba(255, 255, 255, 0.3);
		}

		svg {
			opacity: 1;
		}
	}

	.only_text {
		&:hover {
			background-color: transparent;
			border-color: transparent;
		}
	}
}

.footer_edit_group {
	padding: 18px 24px;
	display: flex;
	align-items: center;

	span {
		margin: auto;
		margin-right: 0px;

		.cancel {
			margin-right: 20px;
		}
	}
}

/* Let's get this party started */
.list_edit_group::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.list_edit_group::-webkit-scrollbar-track {
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 11px;
	// margin-top: 4px;
	margin-bottom: 10px;
}

/* Handle */
.list_edit_group::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #9aa0a7;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.list_edit_group::-webkit-scrollbar-thumb:window-inactive {
	background: #9aa0a7;
}

.filter_list {
	padding: 15px;

	span {
		padding-left: 10px;
	}
}

.groupName {}

.name_psevdo_link {
	display: inline-block;
	height: 23px;
	font-size: inherit;
	vertical-align: top;

	&:hover {
		border-bottom: dotted 2px rgb(14, 14, 14);
	}
}

.triangle_left {
	border-right: 6px solid black;
	border-bottom: 4px solid transparent;
	border-top: 4px solid transparent;
	display: block;
	width: 6px;
	height: 7px;
	position: absolute;
	margin: auto;
	top: 0px;
	bottom: 0px;
	z-index: 1;
	left: 0px;
	right: 0px;
}

.triangle_right {
	border-left: 6px solid black;
	border-bottom: 4px solid transparent;
	border-top: 4px solid transparent;
	display: block;
	width: 6px;
	height: 7px;
	position: absolute;
	margin: auto;
	top: 0px;
	bottom: 0px;
	z-index: 1;
	left: 0px;
	right: 0px;
}

.edit_comment_form {
	padding: 15px;

	.comment_area {
		width: 100%;
		height: 50px;
		border: 1px solid $border_block;
		margin-bottom: 15px;
		padding: 15px;
	}

	.btn_black {
		float: right;
	}
}

@media screen and (max-width: 1100px) {

	.edit_group {
		position: fixed;
		top: 93px;
		left: auto;
		right: 0px;

		.list_edit_group {
			max-height: calc(100vh - 176px);
		}
	}
}

@media screen and (max-width: 768px) {
	.edit_group {
		left: 0;
		right: 0;
		top: 49px;
		position: fixed;

		.list_edit_group {
			max-height: calc(100vh - 176px);
		}
	}

	.events {
		float: none;
		display: block;
		padding-left: 40px;
		margin-bottom: 10px;
	}

	.one_auto {
		height: auto;
	}

	.update_data {
		float: none;
		padding-bottom: 15px;
		display: block;
	}

	.current_status {
		float: none;
	}

	.status {
		padding-bottom: 15px;
		display: block;
	}

	.antena {
		margin-left: 0;
	}


}

@media screen and (max-width: 767px) {
	.edit_group {
		max-width: 100%;
		min-width: 320px;

		.group_name_input {
			max-width: 100%;
		}
	}

	.help_div {
		top: calc(100vh - 104px);
		width: 100%;
		min-width: 100%;
		position: absolute;
	}

	.left_sidebar {
		-webkit-transition: height 0.5s ease-in;
		-moz-transition: height 0.5s ease-in;
		-ms-transition: height 0.5s ease-in;
		-o-transition: height 0.5s ease-in;
		transition: height 0.5s ease-in;
	}

	.trigger_triangle {
		left: 0px;
		top: -20px;
		right: auto;
		width: 100%;
		height: 20px;

	}

	.mapContainer {
		height: calc(100vh - 116px);
	}

	.triangle_left {
		transform: rotate(90deg);
	}

	.triangle_right {
		transform: rotate(90deg);
	}

	.left_sidebar_hide {
		left: 0px;
		top: 116px
	}

	.for_fix {
		position: absolute;
		top: 6px;
		right: 6px;
		width: 16px;
		height: 16px;
		padding: 0px;

		.transparent {
			opacity: 1;
		}
	}

	.one_auto {
		padding-left: 20px;
		height: auto;
		padding-bottom: 20px;
	}

	.hidden_button {
		visibility: visible;
		position: static;
		display: block;
		opacity: 1;
	}

	.status {
		padding-left: 0px;
	}

	.update_data,
	.current_status,
	.events,
	.auto_info {
		float: none;
		display: block;
		margin-bottom: 10px;
		margin-left: 0px;
	}

	.event {
		margin-left: 9px;
	}

}

.SOS {
	width: 30px !important;

}

.SOS_ALARM {
	width: 10px;
}

.blink {
	animation: bliink 1s linear infinite;
}

.vehicles_group {
	.nameForm{
		margin-bottom: 20px;
	}
	.btn_black {
		padding: 4px;
		-webkit-border-radius: 2px;
		border-radius: 2px;
		margin-right: 4px;
	}
}

.groups_on_vehiclePage {
	padding: 10px 0px;
	max-height: 300px;
	overflow: auto;

	.delete {
		position: relative;
		margin-left: 10px;
		width: 15px;
		height: 15px;
		background: transparent;
	}

	.one_group {
		display: flex;
		padding: 7px 0px;
	}

}

.addGroupForm {
	padding: 15px;

	.input {
		-webkit-border-radius: 2px;
		border-radius: 2px;
		margin-right: 15px;
	}

	.btn_black {
		height: 30px;
	}
}

.addToGroup {
	padding: 15px;
	max-height: 300px;
	overflow: auto;

	.one_group {
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		background: #FFF;
		font-size: 14px;
		width: 100%;
		cursor: pointer;
		margin-bottom: 7px;
		text-align: left;
		padding: 7px;
		-webkit-border-radius: 2px;
		border-radius: 2px;

		&:hover {
			background: rgba(128, 128, 128, 0.4);
		}
	}
}

.vehicle_mapping_list {
    background-color: #f5f5f5;
    padding: 16px 16px 16px 8px;
    height: calc(100vh - 96px);
    width: 500px;
    overflow-y: scroll;
    position: relative;
    @extend .scroll;
    min-height: inherit;

    .grey_text {
        color: #9aa0a7;
        font-size: 1.4rem;
        // padding: 23px 0px 28px
    }

    .route_points_list {
        padding-left: 5px;
        margin-bottom: 10px;
    }

    .one_route {
        position: relative;
        background: #FFF;
        padding: 23px 14px 23px;
        margin-bottom: 4px;
        .collapse_btn{
            position: absolute;
            right: 14px;
            top: 19px;
        }
        .point_head {
            // font-weight: bold;
            position: relative;
            p {
                font-size: 16px;
                font-weight: 400;
            }
        }
        .btn_block_point {
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: space-end;
            justify-content: flex-end;

            button {
                position: static;
                top: auto;
                right: auto;
                left: auto;
                bottom: auto;
                margin-left: 6px;
            }

        }
        .hidden_route_info{
            padding-top: 22px;
        }
    }

    .one_mapped_vehicle {
        // border: 1px solid $grey_line;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
        position: relative;
        z-index: 1;
        width: 100%;
        border-bottom: 1px solid $border_block;
        &:before {
            content: '';
            width: 5px;
            height: 5px;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            border: solid 2px rgb(14, 14, 14);
            outline: 1px solid white;
            position: absolute;
            left: 0px;
            background: #FFF;
            top: 14px;
            z-index: 4;
        }

        &:after {
            content: '';
            background: rgb(14, 14, 14);
            width: 1px;
            height: calc(100% - 12px);
            position: absolute;
            left: 4px;
            top: 25px;
            align-self: stretch;
        }

        .grey_line {
            position: absolute;
            bottom: 0px;
            width: 353px;
            right: 0px;
            margin-bottom: 0px;
        }

        &:last-child {
            &:after {
                // top:0px;
                display: none;
            }

            .grey_line {
                display: none;
            }
        }

        .icon_clock {
            padding-top: 10px;
            padding-right: 10px;
        }

        .time_input_container {
            display: block;
            margin-bottom: 10px;

            .days_input,
            .month_input,
            .year_input,
            .hours_input,
            .minutes_input {
                height: 27px;
            }

            label {
                margin-bottom: 5px;
                font-size: 12px;
                line-height: 14px;
                display: block;
            }
        }

        label p {
            margin-bottom: 5px;
            font-size: 12px;
            line-height: 14px;
        }


    }
    .point_name {
        margin-right: 10px;
        min-width: 150px;
        width: fit-content;
        max-width: 90%;
        // display: inline-block;
        margin-left: 0px;
        margin-right: auto;

        padding-right: 25px;
        @include collapsedText;

        span {
            &:first-child {
                font-size: 14px;
                line-height: 12px;
                color: rgb(14, 14, 14);
                display: block;
                max-width: 100%;
                margin-bottom: 4px;
            }

            &:last-child {
                font-size: 12px;
                line-height: 14px;
                color: rgb(154, 160, 167);
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
                height: 14px;
                overflow: hidden;
                display: inline-block;
            }
        }
    }
}


@keyframes bliink {
	0% {
		opacity: 0;
	}

	// 50%{opacity: .5;}
	100% {
		opacity: 1;
	}
}