/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-en_flag {
  background-image: url('../images/sprite.png');
  background-position: 0px 0px;
  width: 32px;
  height: 32px;
}
.icon-ru_flag {
  background-image: url('../images/sprite.png');
  background-position: -42px 0px;
  width: 32px;
  height: 32px;
}
.icon-search_small {
  background-image: url('../images/sprite.png');
  background-position: -42px -42px;
  width: 12px;
  height: 12px;
}
.icon-ua_flag {
  background-image: url('../images/sprite.png');
  background-position: 0px -42px;
  width: 32px;
  height: 32px;
}
